import React, { useState } from 'react';
import { BiSolidCameraPlus } from 'react-icons/bi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Profile = ({ onAvatarUpdate, username }) => {
  const [avatarURL, setAvatarURL] = useState('');
  const [location, setLocation] = useState('');
  const BACKEND_URL = process.env.REACT_APP_API_URL;
  const CLOUD_URL = process.env.REACT_APP_IMAGE_CLOUD;
  const PRESET = process.env.REACT_APP_IMAGE_PRESET;
  const navigate = useNavigate();

  const uploadAvatar = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        console.error('No file selected');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', PRESET);

      const response = await axios.post(CLOUD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newAvatarURL = response.data.secure_url;
      setAvatarURL(newAvatarURL);

      if (onAvatarUpdate) {
        onAvatarUpdate(newAvatarURL); // Send the avatar URL to the parent component
      }
    } catch (error) {
      console.error('Error uploading image to Cloudinary:', error);
    }
  };

  const sendAvatarToBackend = async (avatarURL) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/auth/addImage/${username}`, {
        avatarURL,
        location,
      });

      return response; // Return the response to handle success
    } catch (error) {
      console.error('Error sending avatar to backend:', error);
      throw error;
    }
  };

  const handleProceed = async () => {
    try {
      const response = await sendAvatarToBackend(avatarURL);
      if (response.status === 200) {
        navigate('/'); // Navigate to home page on success
      } else {
        console.error('Failed to update avatar and location');
      }
    } catch (error) {
      console.error('Error during proceed:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8 space-y-6">
        {/* Heading */}
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-gradient-black-white">
            Welcome! Let's create your Avtaar
          </h1>
          <p className="text-sm text-gray-400 mt-2">Get Post Put Everything You Need!</p>
        </div>

        {/* Avatar Upload */}
        <div className="flex flex-col items-center space-y-4">
          {avatarURL ? (
            <img
              src={avatarURL}
              alt="Avatar"
              className="w-32 h-32 rounded-full mb-2 shadow-md"
            />
          ) : (
            <div className="relative w-32 h-32 rounded-full border-2 border-dashed border-gray-600 flex items-center justify-center mb-2">
              <BiSolidCameraPlus className="w-12 h-12 text-gray-500" />
            </div>
          )}
          <input
            type="file"
            onChange={uploadAvatar}
            className="hidden"
            id="avatarInput"
          />
          <label
            htmlFor="avatarInput"
            className="bg-gray-700 text-white px-4 py-2 rounded-full text-sm hover:bg-gray-600 cursor-pointer transition-colors"
          >
            Choose image
          </label>
        </div>

        {/* Location Input */}
        <div className="flex flex-col space-y-2">
          <label htmlFor="location" className="text-sm font-medium text-gray-400">
            Add Your Location
          </label>
          <input
            type="text"
            id="location"
            placeholder="Enter a location"
            className="border border-gray-600 rounded-md p-2 text-sm focus:border-blue-500 focus:outline-none bg-gray-800 text-white"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        {/* Next Button */}
        <button
          onClick={handleProceed}
          disabled={!avatarURL || !location}
          className={`bg-pink-600 text-white px-4 py-2 rounded-full text-sm hover:bg-pink-500 transition-colors ${
            !avatarURL || !location ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Profile;
