import React, { useState } from 'react';
import axios from 'axios';
import { BsCamera } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const AddCourseEventPage = () => {
    const [courseEvent, setCourseEvent] = useState({
        title: '',
        price: '',
        description: '',
        seller: '',
        images: ['', '', '', ''],
        productLink: '',
        deployedLink: '',
        githubLink: '' // Added field for GitHub link
    });

    const navigate = useNavigate();
    const uri = process.env.REACT_APP_API_URL;
    const CLOUD_URL = process.env.REACT_APP_IMAGE_CLOUD;
    const PRESET = process.env.REACT_APP_IMAGE_PRESET;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourseEvent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = async (e, index) => {
        try {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', PRESET);

            const response = await axios.post(
                CLOUD_URL,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: false
                }
            );

            const newImages = [...courseEvent.images];
            newImages[index] = response.data.secure_url;

            setCourseEvent(prevState => ({
                ...prevState,
                images: newImages
            }));

        } catch (error) {
            console.error('Error uploading image to Cloudinary:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(uri + "/product/addProduct", courseEvent, { withCredentials: true });
            if (response.status === 200) {
                window.alert("Product added successfully");
                navigate('/home');
            } else {
                window.alert("Product adding failed");
            }
            setCourseEvent({
                title: '',
                price: '',
                description: '',
                seller: '',
                images: ['', '', '', ''],
                productLink: '',
                deployedLink: '',
                githubLink: '' // Reset GitHub link
            });
        } catch (error) {
            console.error(error);
            window.alert("An error occurred while adding the product");
        }
    };

    return (
        <div className="min-h-screen bg-black text-white">
            <div className="container mx-auto px-4 md:px-8 py-4 md:py-8">
                <h1 className="text-2xl text-gradient-black-white md:text-3xl font-semibold mb-4 md:mb-8 text-center">Add New Product In The World!</h1>
                <form onSubmit={handleSubmit} className="bg-gray-800 p-4 md:p-8 rounded-lg shadow-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-4 md:mb-6">
                        <div>
                            <label htmlFor="title" className="block text-sm md:text-base font-medium">Title:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="title"
                                name="title"
                                value={courseEvent.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="price" className="block text-sm md:text-base font-medium">Price:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="price"
                                name="price"
                                value={courseEvent.price}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="seller" className="block text-sm md:text-base font-medium">Seller:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="seller"
                                name="seller"
                                value={courseEvent.seller}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="productLink" className="block text-sm md:text-base font-medium">Product Link:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="productLink"
                                name="productLink"
                                value={courseEvent.productLink}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="deployedLink" className="block text-sm md:text-base font-medium">Deployed Link:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="deployedLink"
                                name="deployedLink"
                                value={courseEvent.deployedLink}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* New GitHub Link Field */}
                        <div>
                            <label htmlFor="githubLink" className="block text-sm md:text-base font-medium">GitHub Link:</label>
                            <input
                                type="text"
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md text-sm md:text-base bg-gray-900 text-white"
                                id="githubLink"
                                name="githubLink"
                                value={courseEvent.githubLink}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="description" className="block text-sm md:text-base font-medium">Description:</label>
                            <textarea
                                className="mt-1 p-2 w-full border border-gray-600 rounded-md h-24 md:h-32 text-sm md:text-base bg-gray-900 text-white"
                                id="description"
                                name="description"
                                value={courseEvent.description}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>

                        {courseEvent.images.map((image, index) => (
                            <div key={index} className="relative w-full h-24 md:h-32 border border-gray-600 rounded-md">
                                {courseEvent.images[index] ? (
                                    <img src={courseEvent.images[index]} alt={`Image ${index + 1}`} className="w-full h-full object-cover rounded-md" />
                                ) : (
                                    <>
                                        <BsCamera className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 md:w-12 md:h-12 text-gray-400" />
                                        <input
                                            type="file"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onChange={(e) => handleImageChange(e, index)}
                                            required
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <button type="submit" className="bg-white text-black p-2 rounded-md w-full text-sm md:text-base">Add Project/Component</button>
                </form>
            </div>
        </div>
    );
};

export default AddCourseEventPage;
