import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { CiLogout } from 'react-icons/ci';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [type, setType] = useState('');
    const BACKEND_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const logout = async () => {
        try {
            // Send a request to the backend to handle logout and clear the cookie
            const response = await axios.delete(`${BACKEND_URL}/auth/logout`, {
                withCredentials: true // Ensure cookies are sent and deleted
            });
            console.log(response.data.message); // Log the success message (if any)
        } catch (error) {
            console.error('Error during logout:', error.response?.data || error.message);
        }
    };

    const Logout = async () => {
        // Remove tokens from localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("type");

        // Delete accessToken and refreshToken cookies
        await logout();
        // Close the mobile menu
        setIsOpen(false);

        // Redirect to the home page or login page after logout
        navigate("/");
    };

    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/auth/findUser`, {
                    withCredentials: true
                });
                setAvatar(response.data.foundUser.image);
                setType(response.data.foundUser.type);
            } catch (error) {
                console.error('Error fetching avatar:', error);
            }
        };

        fetchAvatar();
    }, [BACKEND_URL]);

    return (
        <nav className="bg-black text-white p-4 flex justify-between items-center relative">
            {/* Logo and Menu for Desktop */}
            <div className="hidden lg:flex items-center">
                <h1 className="text-lg text-gradient lg:text-xl mr-8">codeCELLS</h1>
                <ul className="flex space-x-4 text-sm lg:text-base">
                    <li><NavLink to="/home" className="hover:text-gray-300 py-2 font-medium">Home</NavLink></li>
                    <li><NavLink to="/MyProfile" className="hover:text-gray-300 py-2 font-medium">Profile</NavLink></li>
                    <li><NavLink to="/products" className="hover:text-gray-300 py-2 font-medium">Components</NavLink></li>
                    <li><NavLink to="/earn" className="hover:text-gray-300 py-2 font-medium">Earn</NavLink></li>
                    <li><NavLink to="/contactUs" className="hover:text-gray-300 py-2 font-medium">Contact</NavLink></li>
                    {type === "admin" ? (
                        <li><NavLink to="/admin/adminDashboard" className="hover:text-gray-300 py-2 font-medium">Admin</NavLink></li>
                    ) : (
                        <li><NavLink to="/user/myPurchases" className="hover:text-gray-300 py-2 font-medium">Purchases</NavLink></li>
                    )}
                </ul>
            </div>

            {/* Mobile Menu Button */}
            <div className="lg:hidden flex items-center">
                <button onClick={() => setIsOpen(!isOpen)}>
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        {isOpen ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        )}
                    </svg>
                </button>
            </div>

            {/* Search and Avatar */}
            <div className="flex items-center">
                <div className="relative">
                    <input 
                        type="text" 
                        placeholder="Search" 
                        className="border bg-gray-800 text-white p-2 rounded-l-lg pl-8 focus:outline-none text-sm lg:text-base"
                    />
                    <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-sm lg:text-base">
                        <IoSearch />
                    </div>
                </div>
                <img onClick={() => navigate('/MyProfile')} src={avatar} alt="Avatar" className="w-8 h-8 lg:w-10 lg:h-10 ml-4 cursor-pointer rounded-full border-2 border-gray-600" />
                <button className="bg-gray-700 text-white p-2 ml-4 text-sm lg:text-xl font-extrabold rounded hidden md:block" onClick={Logout}><CiLogout /></button>
            </div>

            {/* Mobile Menu Content */}
            {isOpen && (
                <div className="lg:hidden absolute top-16 left-0 right-0 bg-gray-800 p-4 border border-gray-700 rounded shadow-lg z-50">
                    <ul className="flex flex-col space-y-2 text-sm lg:text-base">
                        <li><NavLink to="/home" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Home</NavLink></li>
                        <li><NavLink to="/products" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Components</NavLink></li>
                        <li><NavLink to="/MyProfile" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Profile</NavLink></li>
                        <li><NavLink to="/earn" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Earn</NavLink></li>
                        <li><NavLink to="/contactUs" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Contact Us</NavLink></li>
                        {type === "admin" ? (
                            <li><NavLink to="/admin/adminDashboard" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Admin</NavLink></li>
                        ) : (
                            <li><NavLink to="/user/myPurchases" className="text-white hover:text-gray-400" onClick={() => setIsOpen(false)}>Purchases</NavLink></li>
                        )}
                    </ul>
                    <button className="bg-gray-700 text-white p-2 mt-4 rounded text-sm lg:text-base" onClick={Logout}>LOG OUT</button>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
