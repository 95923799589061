import React from 'react';
import { FaFileDownload } from 'react-icons/fa';
import {useParams} from 'react-router-dom'
import {useState,useEffect} from 'react'
import axios from "axios"
const DownloadPage = () => {
const [product,setProduct]  =useState('')

const { id } = useParams();
const uri = process.env.REACT_APP_API_URL;

useEffect(() => {
    const fetchProductById = async () => {
        try {
            const response = await axios.get(`${uri}/product/productById/${id}`, { withCredentials: true });
            setProduct(response.data);
            
        } catch (error) {
           console.error(error)
        } 
    };

    fetchProductById();
}, [id]);




  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <div className="flex items-center justify-center mb-8">
          <FaFileDownload className="text-5xl text-blue-500 mr-4" />
          <h1 className="text-2xl font-semibold">Download PDF</h1>
        </div>
        <p className="text-gray-600 text-center mb-8">
          Click the button below to download the PDF file.
        </p>
        <div className="flex justify-center">
          <a
            href={product.productLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-md text-lg font-semibold transition duration-300 ease-in-out"
          >
            Download PDF
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
