import React from 'react';
import {useNavigate} from 'react-router-dom'
const Modal = ({ onClose }) => {
    const navigate = useNavigate()
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="bg-white rounded-lg p-8 relative z-50">
                <h1 className="text-3xl font-semibold text-green-500 mb-4">
                    Congratulations!
                </h1>
                <p className="text-gray-600 mb-6">
                    You have successfully bought a product from CodeCells.
                </p>
                <button 
                    className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors duration-300"
                    onClick={()=>navigate('/myProducts')}
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default Modal;
