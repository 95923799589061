import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RiDashboardLine } from 'react-icons/ri';
import { AiFillDollarCircle } from 'react-icons/ai';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const Dashboard = () => {
    const [today, setToday] = useState('');
    const [week, setWeek] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [lifetime, setLifetime] = useState('');
    const [salesData, setSalesData] = useState([]);
    const uri = process.env.REACT_APP_API_URL;

    const fetchSales = async () => {
        try {
            const responseToday = await axios.get(`${uri}/transactions/summary/today`, { withCredentials: true });
            setToday(responseToday.data.sumOfPrices.toFixed(2));

            const responseWeek = await axios.get(`${uri}/transactions/summary/week`, { withCredentials: true });
            setWeek(responseWeek.data.sumOfPrices.toFixed(2));

            const responseMonth = await axios.get(`${uri}/transactions/summary/month`, { withCredentials: true });
            setMonth(responseMonth.data.sumOfPrices.toFixed(2));

            const responseYear = await axios.get(`${uri}/transactions/summary/year`, { withCredentials: true });
            setYear(responseYear.data.sumOfPrices.toFixed(2));

            const responseAll = await axios.get(`${uri}/transactions/summary/all`, { withCredentials: true });
            setLifetime(responseAll.data.sumOfPrices.toFixed(2));

            // Example data for the chart (you should replace this with actual data)
            setSalesData([
                { label: 'Today', value: responseToday.data.sumOfPrices },
                { label: 'Week', value: responseWeek.data.sumOfPrices },
                { label: 'Month', value: responseMonth.data.sumOfPrices },
                { label: 'Year', value: responseYear.data.sumOfPrices },
                { label: 'Lifetime', value: responseAll.data.sumOfPrices },
            ]);
        } catch (error) {
            console.error('Error fetching sales data:', error);
        }
    };

    useEffect(() => {
        fetchSales();
    }, []);

    const chartData = {
        labels: salesData.map(data => data.label),
        datasets: [
            {
                label: 'Sales Data',
                data: salesData.map(data => data.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: true,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: 'white',
                },
            },
        },
    };

    return (
        <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen text-white">
            <div className="container mx-auto px-4 py-8">
                <h2 className="text-4xl font-bold mb-8 text-center">Sales Dashboard</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
                    <SalesCard title="Total Sales Today" amount={today} icon={<RiDashboardLine className="text-4xl text-white" />} color="bg-blue-600" />
                    <SalesCard title="Total Sales This Week" amount={week} icon={<AiFillDollarCircle className="text-4xl text-white" />} color="bg-green-600" />
                    <SalesCard title="Total Sales This Month" amount={month} icon={<RiDashboardLine className="text-4xl text-white" />} color="bg-purple-600" />
                    <SalesCard title="Total Sales This Year" amount={year} icon={<AiFillDollarCircle className="text-4xl text-white" />} color="bg-orange-600" />
                    <SalesCard title="Total Sales Lifetime" amount={lifetime} icon={<RiDashboardLine className="text-4xl text-white" />} color="bg-red-600" />
                </div>
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-4">Sales Overview</h3>
                    <div className="h-64">
                        <Line data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SalesCard = ({ title, amount, icon, color }) => {
    return (
        <div className={`p-6 rounded-lg shadow-md ${color} text-white hover:shadow-xl transform transition-all hover:scale-105`}>
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className="flex-grow">
                    <h5 className="text-xl font-semibold">{title}</h5>
                    <p className="text-3xl font-bold mt-1">₹{amount}</p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
