import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LeftImage from "../../assests/signup.jpeg";
import Avtaar from './Avtaar';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    fName: '',
    lName: '',
    email: ''
  });
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false); 
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");

    if (strongRegex.test(password)) {
      setPasswordStrength('Strong');
      setPasswordError('');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('Medium');
      setPasswordError('');
    } else {
      setPasswordStrength('Weak');
      setPasswordError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setError('Please fill out all fields correctly.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/auth/register`, formData);

      if (response.status === 201) {
        setSignupSuccess(true);
      } else if (response.status === 209) {
        setError("Email or Username already in use");
      } else {
        setError('Failed to register');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      formData.username !== '' &&
      formData.password !== '' &&
      formData.fName !== '' &&
      formData.lName !== '' &&
      formData.email !== '' &&
      passwordStrength === 'Strong'
    );
  };

  const handleImageUpload = (url) => {
    // Handle image upload
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-transparent text-white">
      {/* Main Container */}
      <div className="relative w-full max-w-screen-lg bg-gray-800 rounded-lg shadow-lg flex flex-col md:flex-row">
        {/* Form Section */}
        <div className="w-full md:w-1/2 p-6 md:p-12 flex justify-center">
          {!signupSuccess && (
            <div className="w-full">
              <h1 className="text-2xl md:text-3xl text-gradient-black-white font-bold mb-6 text-center">Create an Account</h1>

              {/* Form */}
              {error && <p className="text-red-500 mb-4 text-center text-sm">{error}</p>}

              <form className="space-y-4 w-full" onSubmit={handleSubmit}>
                {/* First Name and Last Name */}
                <div className="flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-4">
                  <div className="flex-1 flex flex-col">
                    <label htmlFor="fName" className="text-sm font-semibold mb-2">First Name</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="fName"
                      id="fName"
                      className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full h-10 text-sm"
                      required
                      disabled={loading}
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <label htmlFor="lName" className="text-sm font-semibold mb-2">Last Name</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="lName"
                      id="lName"
                      className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full h-10 text-sm"
                      required
                      disabled={loading}
                    />
                  </div>
                </div>

                {/* Username */}
                <div className="flex flex-col w-full">
                  <label htmlFor="username" className="text-sm font-semibold mb-2">Username</label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="username"
                    id="username"
                    className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full h-10 text-sm"
                    required
                    disabled={loading}
                  />
                </div>

                {/* Email */}
                <div className="flex flex-col w-full">
                  <label htmlFor="email" className="text-sm font-semibold mb-2">Email</label>
                  <input
                    onChange={handleChange}
                    type="email"
                    name="email"
                    id="email"
                    className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full h-10 text-sm"
                    required
                    disabled={loading}
                  />
                </div>

                {/* Password */}
                <div className="flex flex-col w-full relative">
                  <label htmlFor="password" className="text-sm font-semibold mb-2">Password</label>
                  <input
                    onChange={handleChange}
                    placeholder={passwordError}
                    type="password"
                    name="password"
                    id="password"
                    className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full h-10 text-sm"
                    required
                    disabled={loading}
                  />
                  {passwordStrength && (
                    <p className={`text-xs mt-1 ${passwordStrength === 'Weak' ? 'text-red-500' : 'text-gray-400'}`}>
                      {passwordStrength}
                    </p>
                  )}
                </div>

                {/* Sign Up Button */}
                <button
                  type="submit"
                  className={`w-full bg-blue-600 text-white py-2 rounded-md mt-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="loader animate-spin w-4 h-4 border-t-2 border-white rounded-full mx-auto"></div>
                  ) : (
                    'Sign Up'
                  )}
                </button>
              </form>
            </div>
          )}
        </div>

        {/* Background Image Section */}
        <div className="hidden md:block md:w-1/2 bg-cover bg-center bg-no-repeat rounded-r-lg" style={{ backgroundImage: `url(${LeftImage})` }}></div>

        {/* Avtaar Section */}
        {signupSuccess && (
          <div
            className="w-full md:w-1/2 flex-shrink-0 flex items-center justify-center p-6 bg-gray-900 transition-all duration-500 ease-in-out"
            style={{ opacity: signupSuccess ? 1 : 0, transform: signupSuccess ? 'translateX(0)' : 'translateX(100%)' }}
          >
            <Avtaar onImageUpload={handleImageUpload} username={formData.username} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
