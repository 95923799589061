import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AllComponents = () => {
  const [components, setComponents] = useState([]);
  const [editingComponent, setEditingComponent] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [updatedAdminStatus, setUpdatedAdminStatus] = useState('');
  const [updatedImageUrl, setUpdatedImageUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchComponents = async () => {
      try {
        const response = await axios.get(`${backendUrl}/code/codes/all`, {
          withCredentials: true,
        });
        setComponents(response.data.data);
      } catch (error) {
        console.error('Error fetching components:', error);
      }
    };

    fetchComponents();
  }, [backendUrl]);

  const handleOpenModal = (component) => {
    setEditingComponent(component);
    setUpdatedPrice(component.price);
    setUpdatedAdminStatus(component.adminStatus);
    setUpdatedImageUrl(component.imageUrl || '');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingComponent(null);
    setUpdatedPrice('');
    setUpdatedAdminStatus('');
    setUpdatedImageUrl('');
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${backendUrl}/code/${editingComponent._id}`, {
        price: updatedPrice,
        adminStatus: updatedAdminStatus,
        imageUrl: updatedImageUrl,
      }, {
        withCredentials: true,
      });
      setComponents(components.map(component =>
        component._id === editingComponent._id ? {
          ...component,
          price: updatedPrice,
          adminStatus: updatedAdminStatus,
          imageUrl: updatedImageUrl,
        } : component
      ));
      handleCloseModal();
    } catch (error) {
      console.error('Error updating component:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <h1 className="text-3xl font-bold mb-8">Components</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full text-white bg-gray-800 rounded-lg shadow-lg">
          <thead>
            <tr>
              <th className="text-left p-4">User</th>
              <th className="text-left p-4">Code ID</th>
              <th className="text-left p-4">Code Type</th>
              <th className="text-left p-4">Created At</th>
              <th className="text-left p-4">Price</th>
              <th className="text-left p-4">Admin Status</th>
              <th className="text-left p-4">Image URL</th>
              <th className="text-left p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {components.map(component => (
              <tr key={component._id} className="border-b border-gray-700">
                <td className="p-4">{component.user}</td>
                <td className="p-4">{component.codeId}</td>
                <td className="p-4">{component.codeType}</td>
                <td className="p-4">{new Date(component.createdAt).toLocaleString()}</td>
                <td className="p-4">
                  {editingComponent?._id === component._id ? (
                    <input
                      type="text"
                      value={updatedPrice}
                      onChange={(e) => setUpdatedPrice(e.target.value)}
                      className="bg-gray-700 text-white p-2 rounded"
                    />
                  ) : (
                    component.price
                  )}
                </td>
                <td className="p-4">
                  {editingComponent?._id === component._id ? (
                    <select
                      value={updatedAdminStatus}
                      onChange={(e) => setUpdatedAdminStatus(e.target.value)}
                      className="bg-gray-700 text-white p-2 rounded"
                    >
                      <option value="accepted">Accepted</option>
                      <option value="pending">Pending</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  ) : (
                    component.adminStatus
                  )}
                </td>
                <td className="p-4">
                  {editingComponent?._id === component._id ? (
                    <input
                      type="text"
                      value={updatedImageUrl}
                      onChange={(e) => setUpdatedImageUrl(e.target.value)}
                      className="bg-gray-700 text-white p-2 rounded"
                      placeholder="Enter Image URL"
                    />
                  ) : (
                    component.imageUrl ? (
                      <a href={component.imageUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                        View Image
                      </a>
                    ) : (
                      'No Image'
                    )
                  )}
                </td>
                <td className="p-4 flex space-x-2">
                  {editingComponent?._id === component._id ? (
                    <button
                      onClick={handleUpdate}
                      className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => handleOpenModal(component)}
                      className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-lg"
                    >
                      Edit
                    </button>
                  )}
                  <button
                    onClick={() => navigate(`/preview/${component.codeId}`)}
                    className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg"
                  >
                    Open
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Editing */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-6 rounded-lg max-w-full max-h-full overflow-auto flex flex-col">
            <div className="mb-4 flex justify-center">
              <img
                src={updatedImageUrl}
                alt="Component"
                className="max-w-full max-h-60 object-cover rounded-lg"
              />
            </div>
            <h2 className="text-xl font-bold mb-4">Edit Component</h2>
            <div className="mb-4">
              <label className="block text-gray-300 mb-2">Price</label>
              <input
                type="text"
                value={updatedPrice}
                onChange={(e) => setUpdatedPrice(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 mb-2">Admin Status</label>
              <select
                value={updatedAdminStatus}
                onChange={(e) => setUpdatedAdminStatus(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full"
              >
                <option value="accepted">Accepted</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 mb-2">Image URL</label>
              <input
                type="text"
                value={updatedImageUrl}
                onChange={(e) => setUpdatedImageUrl(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded w-full"
                placeholder="Enter Image URL"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCloseModal}
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllComponents;
