import React from 'react';
import { FaProjectDiagram } from 'react-icons/fa';
import { AiFillAppstore } from 'react-icons/ai';
import { MdGavel } from 'react-icons/md';
import image1 from '../../assests/heroImage.jpg'; // Corrected path to the image
import image2 from '../../assests/heroImg.png'; // Corrected path to the image
import image3 from '../../assests/f1.png'; // Corrected path to the image
import { useNavigate } from 'react-router-dom';

const AddSection = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    return (
        <div className="bg-gray-900 py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-gradient-black-white text-center mb-12">Get Started with Your Next Action</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    
                    <SectionCard
                        title="Sell your Project"
                        icon={<FaProjectDiagram className="text-5xl text-white" />}
                        backgroundImage={image1}
                        onClick={() => navigate('/addProduct')} // Navigate to Add Project page
                    />
                    
                    <SectionCard
                        title="Sell your Component"
                        icon={<AiFillAppstore className="text-5xl text-white" />}
                        backgroundImage={image2}
                        onClick={() => navigate('/code/createCode')} // Navigate to Add Component page
                    />
                    
                    <SectionCard
                        title="Conver Your UI Page To Code"
                        icon={<MdGavel className="text-5xl text-white" />}
                        backgroundImage={image3}
                        onClick={() => navigate('/component/getCode')} // Navigate to Add Bid page
                    />
                    
                </div>
            </div>
        </div>
    );
};

const SectionCard = ({ title, icon, backgroundImage, onClick }) => {
    return (
        <div
            className="relative h-64 rounded-lg shadow-lg overflow-hidden transform transition-all hover:scale-105 cursor-pointer"
            onClick={onClick} // Add onClick to handle navigation
        >
            <div 
                className="absolute inset-0 bg-contain bg-center filter blur-sm"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            ></div>
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="relative z-10 flex flex-col justify-center items-center h-full text-center text-white p-6">
                <div className="mb-4">
                    {icon}
                </div>
                <h3 className="text-2xl font-semibold">{title}</h3>
            </div>
        </div>
    );
};

export default AddSection;
