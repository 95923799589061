import React from 'react';
import { motion } from 'framer-motion';
import { FaPlusCircle, FaCode } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const EarnPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center p-8">
      <header className="text-center mb-12">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to Our Code Marketplace</h1>
        <p className="text-lg md:text-xl mb-8">
          Choose an option below to either add your own project or add  components.
        </p>
      </header>

      <div className="flex flex-col md:flex-row gap-8 w-full max-w-4xl">
        {/* Add Project Section */}
        <motion.div
          className="bg-gray-800 p-8 rounded-lg shadow-lg flex flex-col items-center justify-center cursor-pointer hover:bg-gray-700 transition-colors"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          onClick={() => handleNavigate('/addProduct')}
        >
          <FaPlusCircle className="text-blue-500 text-6xl mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Add Your Project</h2>
          <p className="text-lg text-gray-300">
            Share your projects and start earning by adding your components to our marketplace. Earn Upto 50
          </p>
        </motion.div>

        {/* View Components Section */}
        <motion.div
          className="bg-gray-800 p-8 rounded-lg shadow-lg flex flex-col items-center justify-center cursor-pointer hover:bg-gray-700 transition-colors"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          onClick={() => handleNavigate('/code/createCode')}
        >
          <FaCode className="text-green-500 text-6xl mb-4" />
          <h2 className="text-2xl font-semibold mb-2">Add Components</h2>
          <p className="text-lg text-gray-300">
           Create a Browse to our collection of components and see what's available for integration for Users. Earn upto 20
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default EarnPage;
