import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserBidsPage = ({ content, route, route2 }) => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const BACKEND_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBids = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/${route}/${route2}/${content}`, {
          withCredentials: true
        });
        setBids(response.data.data);
      } catch (error) {
        setError('Failed to fetch bids.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBids();
  }, [BACKEND_URL, route, route2, content]);

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 sm:p-8">
      <h1 className="text-2xl sm:text-4xl font-bold text-gradient-black-white mb-6 text-center">
        {content} Bids
      </h1>

      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="animate-spin w-12 h-12 border-4 border-t-4 border-gray-600 border-t-blue-500 rounded-full"></div>
        </div>
      ) : error ? (
        <div className="text-red-400 text-center">{error}</div>
      ) : (
        <div className="space-y-6">
          {bids.length > 0 ? (
            bids.map((bid) => (
              <div key={bid._id} className="flex flex-col sm:flex-row bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg border border-gray-700 space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="flex-shrink-0 w-full sm:w-1/2 h-[50vh]">
                  <img src={bid.imageUrl} alt="Bid" className="w-full h-full object-cover rounded-lg" />
                </div>
                <div className="flex-1 flex flex-col justify-between">
                  <div>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-2">Budget: ${bid.budget}</h2>
                    <p className="text-base sm:text-lg mb-2">User Status: {bid.UserStatus}</p>
                    <p className="text-base sm:text-lg mb-2">Admin Status: {bid.AdminStatus}</p>
                    <p className="text-base sm:text-lg mb-2">Bid Price: ${bid.bidPrice}</p>
                    <p className="text-sm text-gray-400 mb-2">Description: {bid.description}</p>
                    <p className="text-sm text-gray-400">Target Date: {new Date(bid.completionDate).toLocaleDateString()}</p>
                  </div>
                  {content === "user" ? (
                    <button
                      onClick={() => navigate(`/update/${bid._id}`)}
                      className="mt-4 bg-pink-600 text-white px-4 py-2 rounded-md text-sm hover:bg-pink-500 transition-colors"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      onClick={() => navigate(`/Bid/${bid._id}`)}
                      className="mt-4 bg-pink-600 text-white px-4 py-2 rounded-md text-sm hover:bg-pink-500 transition-colors"
                    >
                      Make Bid
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-400">No bids found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserBidsPage;
