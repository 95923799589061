import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

const OrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const uri = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch orders
        const ordersResponse = await axios.get(`${uri}/transactions/allTransactions`, { withCredentials: true });
        setOrders(ordersResponse.data);

        // Fetch users
        const usersResponse = await axios.get(`${uri}/auth/allUsers`, { withCredentials: true });
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [uri]);

  const getEmail = (userId) => {
    const user = users.find(user => user._id === userId);
    return user ? user.email : '';
  };

  const getUsername = (userId) => {
    const user = users.find(user => user._id === userId);
    return user ? user.username : '';
  };

  const getStatusIcon = (status) => {
    return status === 'completed' ? (
      <AiFillCheckCircle className="text-green-400 text-xl" />
    ) : (
      <AiFillCloseCircle className="text-red-400 text-xl" />
    );
  };

  return (
    <div className="bg-black min-h-screen text-white">
      <div className="container mx-auto mt-8 p-6 rounded-md bg-gray-900 shadow-lg">
        <h2 className="text-3xl font-semibold mb-6 text-center">Order Table</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto text-white border-collapse border border-gray-700">
            <thead className="bg-gray-800 ">
              <tr>
                <th className="py-2 px-4 border-b border-gray-700">Sr no</th>
                <th className="py-2 px-4 border-b border-gray-700">Username</th>
                <th className="py-2 px-4 border-b border-gray-700">Email</th>
                <th className="py-2 px-4 border-b border-gray-700">Product ID</th>
                <th className="py-2 px-4 border-b border-gray-700">Price</th>
                <th className="py-2 px-4 border-b border-gray-700">Referral Code</th>
                <th className="py-2 px-4 border-b border-gray-700">Transaction ID</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {orders.map((order, index) => (
                <tr key={index} className="border-b border-gray-700">
                  <td className="py-2 px-4">{index + 1}</td>
                  <td className="py-2 px-4">{getUsername(order.userId)}</td>
                  <td className="py-2 px-4">{getEmail(order.userId)}</td>
                  <td className="py-2 px-4">{order.ProductId}</td>
                  <td className="py-2 px-4">${order.price}</td>
                  <td className="py-2 px-4">
                    {getStatusIcon(order.refralCode)}
                  </td>
                  <td className="py-2 px-4">
                    {order.transactionId}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderTable;
