import React, { useState } from 'react';
import axios from 'axios';

const UpdateBidModal = ({ bid, closeModal }) => {
  const [updatedBudget, setUpdatedBudget] = useState(bid.budget);
  const [updatedAdminStatus, setUpdatedAdminStatus] = useState(bid.AdminStatus);
  const [updatedCompletionDate, setUpdatedCompletionDate] = useState(bid.completionDate);

  const backendUrl = process.env.REACT_APP_API_URL;

  const handleSave = async () => {
    try {
      await axios.put(
        `${backendUrl}/bid/bids/${bid._id}`,
        {
          budget: updatedBudget,
          adminStatus: updatedAdminStatus,
          completionDate: updatedCompletionDate,
        },
        { withCredentials: true }
      );

      closeModal();
    } catch (error) {
      console.error('Error updating bid:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-1/3">
        <h2 className="text-2xl font-bold mb-4">Update Bid</h2>
        <div className="mb-4">
          <label className="block mb-2">Budget</label>
          <input
            type="text"
            value={updatedBudget}
            onChange={(e) => setUpdatedBudget(e.target.value)}
            className="w-full p-2 bg-gray-700 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Admin Status</label>
          <select
            value={updatedAdminStatus}
            onChange={(e) => setUpdatedAdminStatus(e.target.value)}
            className="w-full p-2 bg-gray-700 text-white rounded"
          >
            <option value="accepted">Accepted</option>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Completion Date</label>
          <input
            type="date"
            value={updatedCompletionDate}
            onChange={(e) => setUpdatedCompletionDate(e.target.value)}
            className="w-full p-2 bg-gray-700 text-white rounded"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSave}
            className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg"
          >
            Save
          </button>
          <button
            onClick={closeModal}
            className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateBidModal;
