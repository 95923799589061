// src/components/UpdateModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // For accessibility

const UpdateModal = ({ isOpen, onRequestClose, user, onUpdate }) => {
  const [verification, setVerification] = useState('');
  const [earnings, setEarnings] = useState('');

  useEffect(() => {
    if (user) {
      setVerification(user.verification || 'pending');
      setEarnings(user.earnings || '');
    }
  }, [user]);

  const handleUpdate = () => {
    onUpdate(user.username, verification, earnings);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-gray-900 bg-opacity-75"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-gray-800 text-white p-6 rounded-lg max-w-md mx-auto w-full">
        <h2 className="text-xl font-bold mb-4">Update User</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Verification Status</label>
          <select
            value={verification}
            onChange={(e) => setVerification(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full"
          >
            <option value="accepted">Accepted</option>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Earnings</label>
          <input
            type="text"
            value={earnings}
            onChange={(e) => setEarnings(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded w-full"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onRequestClose}
            className="bg-gray-600 hover:bg-gray-700 px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg"
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
