import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_API_URL;

const ImageGallery = () => {
  const [products, setProducts] = useState([]); // State to store product objects
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate()
  // Fetch product data (first 10 objects with ProductId)
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/transactions/allPruchases/component`, {
          withCredentials: true, // Include cookies or auth if needed
        });

        const productData = response.data.data.slice(0, 10); // Fetch first 10 objects
        setProducts(productData); // Set the products array
      } catch (err) {
        console.error('Error fetching product data:', err);
        setError('Failed to load products.');
      }
    };

    fetchProducts();
  }, []);

  // Fetch images for each product by using ProductId
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageRequests = products.map((product) =>
          axios.get(`${backendUrl}/code/component/${product.ProductId}`, {
            withCredentials: true,
          })
        );
        const responses = await Promise.all(imageRequests);

        const newImages = responses.reduce((acc, response, index) => {
          if (response.data && response.data.imageUrl) {
            acc[products[index].ProductId] = response.data.imageUrl; // Map ProductId to imageUrl
          }
          return acc;
        }, {});

        setImages(newImages);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Failed to load images.');
      }
    };

    if (products.length > 0) {
      fetchImages();
    }
  }, [products]);

  // Show loading spinner while data is being fetched
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <FaSpinner className="animate-spin text-5xl" />
      </div>
    );
  }

  // Show error message if there's an issue
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-10">
      <h1 className="text-3xl font-bold text-center text-white mb-10">Component Purchased Image Gallery</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {products.map((product) => (
          <div 
          onClick={()=>navigate(`/preview/${product.ProductId}`)}
          key={product.ProductId} className="relative group overflow-hidden bg-gray-800 rounded-lg shadow-lg">
            {images[product.ProductId] ? (
              <img
                src={images[product.ProductId]}
                alt={`Product ${product.ProductId}`}
                className="w-full h-64 object-contain transition-transform duration-500 group-hover:scale-110"
              />
            ) : (
              <div className="w-full h-64 flex items-center justify-center bg-gray-700 text-white">
                No Image Available
              </div>
            )}
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex items-center justify-center">
              <p className="text-white text-lg font-semibold">Product {product.ProductId}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
