import React from 'react';
import { FaCode } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import "./Style.css";
import heroImage from "../../assests/heroImage.jpg";
import heroImage2 from "../../assests/Design2.png";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section className="relative h-screen pt-16 md:pt-0 flex flex-col my-28 md:mt-0 justify-center items-center bg-black text-white">
      <div className="absolute inset-0 z-0">
        <img 
          src={heroImage} 
          alt="Hero" 
          className="w-full h-full object-cover opacity-30 blur-sm" 
        />
      </div>
      <div className="relative z-10 text-center max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <FaCode className="text-7xl mb-4 hidden md:flex animate-bounce text-gray-300 shadow-lg" />
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between space-y-6 md:space-y-0">
          <div className="md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl md:text-5xl font-extrabold mb-4 text-gradient bg-clip-text bg-gradient-to-r from-gray-300 to-gray-600">
              Creative Code Components
            </h2>
            <p className="text-md md:text-2xl font-semibold">
              Discover creative and innovative code components for your projects
            </p>
            <h2 className="text-xl md:text-xl font-extrabold mb-4 text-gradient bg-clip-text bg-gradient-to-r from-gray-300 to-gray-600">
            Earn Upto Rupee 50  Selling Components
            </h2>
            <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
              <button 
                onClick={() => navigate('/code/createCode')}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md mb-4 sm:mb-0 transition-colors duration-300"
              >
                Create UI to Code
              </button>
              <button 
                onClick={() => navigate('/component/getCode')}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
              >
                Buy Code
              </button>
            </div>
          </div>
          <div className="md:w-1/2 ">
            <img 
              src={heroImage2} 
              alt="Hero Image" 
              className="rounded-lg transform transition-transform hover:scale-105 duration-300 ease-in-out mx-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
