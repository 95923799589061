import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BiSolidCameraPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const BidUploadPage = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [budget, setBudget] = useState('');
  const [description, setDescription] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const CLOUD_URL = process.env.REACT_APP_IMAGE_CLOUD;
  const PRESET = process.env.REACT_APP_IMAGE_PRESET;
  const BACKEND_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const today = new Date();
    const minDate = new Date(today.setDate(today.getDate() + 3));
    setCompletionDate(minDate.toISOString().split('T')[0]);
  }, []);

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      setError('Please select an image to upload.');
      return;
    }

    setUploading(true);
    setError('');

    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', PRESET);

    try {
      const response = await axios.post(CLOUD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImageUrl(response.data.secure_url);
      setUploading(false);
    } catch (error) {
      setError('Failed to upload image. Please try again.');
      setUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (!imageUrl || !budget || !description || !completionDate) {
      setError('Please complete all fields.');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/bid/bids`,
        {
          imageUrl,
          budget,
          description,
          completionDate,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        setImage(null);
        setImageUrl('');
        setBudget('');
        setDescription('');
        setCompletionDate('');
        navigate(`/MyProfile`);
      } else {
        setError('Submission failed. Please try again.');
      }
    } catch (error) {
      setError('Failed to send data to the server. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white px-4 py-8">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="w-16 h-16 border-t-4 border-blue-600 border-solid rounded-full animate-spin"></div>
        </div>
      )}
      <div className="w-full max-w-6xl bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        {/* Left Side - Image Upload */}
        <div className="flex flex-col items-center space-y-4 md:w-1/2">
          {imageUrl ? (
            <img src={imageUrl} alt="Uploaded" className="w-full max-w-xs h-auto rounded-lg shadow-md" />
          ) : (
            <div className="relative w-full max-w-xs h-64 rounded-lg border-2 border-dashed border-gray-600 flex items-center justify-center">
              <BiSolidCameraPlus className="w-16 h-16 text-gray-500" />
            </div>
          )}
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="imageInput"
          />
          <label
            htmlFor="imageInput"
            className="bg-gray-700 text-white px-4 py-2 rounded-full text-sm hover:bg-gray-600 cursor-pointer transition-colors"
            onClick={() => document.getElementById('imageInput').click()}
          >
            Choose Image
          </label>
          <button
            onClick={handleUpload}
            disabled={uploading}
            className="bg-blue-600 text-white px-4 py-2 rounded-full text-sm hover:bg-blue-500 transition-colors"
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>

        {/* Right Side - Form */}
        <div className="flex flex-col space-y-4 md:w-1/2">
          <h1 className="text-2xl font-semibold">Bid Details</h1>

          <div className="flex flex-col space-y-2">
            <label htmlFor="budget" className="text-sm font-medium text-gray-400">Budget</label>
            <input
              type="number"
              id="budget"
              placeholder="Enter your budget"
              className="border border-gray-600 rounded-md p-2 text-sm focus:border-blue-500 focus:outline-none bg-gray-800 text-white"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            
            <label htmlFor="description" className="text-sm font-medium text-gray-400">Description</label>
            <textarea
              id="description"
              placeholder="Enter a brief description of your bid"
              className="border border-gray-600 rounded-md p-2 text-sm focus:border-blue-500 focus:outline-none bg-gray-800 text-white"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <label htmlFor="completionDate" className="text-sm font-medium text-gray-400">Completion Date</label>
            <input
              type="date"
              id="completionDate"
              className="border border-gray-600 rounded-md p-2 text-sm focus:border-blue-500 focus:outline-none bg-gray-800 text-white"
              value={completionDate}
              min={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
            />
          </div>

          <button
            onClick={handleSubmit}
            disabled={!imageUrl || !budget || !description || !completionDate}
            className={`bg-pink-600 text-white px-4 py-2 rounded-full text-sm hover:bg-pink-500 transition-colors ${(!imageUrl || !budget || !description || !completionDate) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Submit
          </button>

          {error && <p className="text-red-400 text-center mt-4">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default BidUploadPage;
