import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillDelete, AiOutlinePlus } from 'react-icons/ai';

const UpdateProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({
    title: '',
    price: '',
    seller: '',
    description: '',
    images: [],
    githubLink:""
  });
  const [imagePreview, setImagePreview] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const uri = process.env.REACT_APP_API_URL;
  const CLOUDINARY_URL = process.env.REACT_APP_IMAGE_CLOUD;
  const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_IMAGE_PRESET;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${uri}/product/productById/${id}`, {
          withCredentials: true
        });
        if (response && response.data) {
          setProduct(response.data);
          setImagePreview(response.data.image || []);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, uri]);

  const uploadImageToCloudinary = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

      const response = await axios.post(
        CLOUDINARY_URL,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: false,
        }
      );
      return response.data.secure_url;
    } catch (error) {
      console.error('Error uploading to Cloudinary:', error);
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const imageUrl = await uploadImageToCloudinary(file);
      setImagePreview([...imagePreview, imageUrl]);
    } catch (error) {
      console.error('Error uploading to Cloudinary:', error);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...imagePreview];
    updatedImages.splice(index, 1);
    setImagePreview(updatedImages);
  };

  const handleDeleteProduct = async () => {
    try {
      await axios.delete(`${uri}/product/deleteProduct/${id}`, {
        withCredentials: true
      });
      navigate('/products');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProduct = {
        ...product,
        image: imagePreview
      };
      await axios.put(`${uri}/product/updateProduct/${id}`, updatedProduct, {
        withCredentials: true
      });
      navigate('/products');
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 min-h-screen py-12 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-semibold mb-8 text-center">Update Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="title" className="block text-xl font-semibold mb-2">Title</label>
              <input 
                type="text" 
                id="title" 
                name="title" 
                value={product.title} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-800 text-white" 
              />
            </div>
            <div>
              <label htmlFor="price" className="block text-xl font-semibold mb-2">Price</label>
              <input 
                type="text" 
                id="price" 
                name="price" 
                value={product.price} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-800 text-white" 
              />
            </div>
            <div>
              <label htmlFor="seller" className="block text-xl font-semibold mb-2">Seller</label>
              <input 
                type="text" 
                id="seller" 
                name="seller" 
                value={product.seller} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-800 text-white" 
              />
            </div>
            <div>
              <label htmlFor="githubLink" className="block text-xl font-semibold mb-2">GitHub Link</label>
              <input 
               type="text"
                id="githubLink" 
                name="githubLink" 
                value={product.githubLink} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-800 text-white h-32"
              ></input>
            </div>
            <div>
              <label htmlFor="description" className="block text-xl font-semibold mb-2">Description</label>
              <textarea 
                id="description" 
                name="description" 
                value={product.description} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-600 rounded-md bg-gray-800 text-white h-32"
              ></textarea>
            </div>
         
          </div>

          <div className="mt-8">
            <h3 className="text-2xl font-semibold mb-4">Product Images</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {imagePreview.map((image, index) => (
                <div key={index} className="relative group">
                  <img src={image} alt={`Product ${index + 1}`} className="w-full h-32 object-cover rounded-md bg-gray-700" />
                  <button 
                    type="button" 
                    className="absolute top-0 right-0 mt-2 mr-2 p-2 bg-red-600 rounded-full text-white group-hover:block"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <AiFillDelete />
                  </button>
                </div>
              ))}
              <div className="relative group">
                <label htmlFor="imageInput" className="flex items-center justify-center w-full h-32 border-dashed border-2 border-gray-600 rounded-md cursor-pointer bg-gray-700 hover:border-blue-500">
                  <AiOutlinePlus className="text-5xl text-gray-400" />
                </label>
                <input 
                  type="file" 
                  id="imageInput" 
                  className="hidden" 
                  onChange={handleImageChange} 
                />
              </div>
            </div>
          </div>

          <div className="mt-8 flex justify-end">
            <button 
              type="button" 
              className="px-6 py-2 bg-red-600 text-white rounded-md mr-4 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={handleDeleteProduct}
            >
              Delete Product
            </button>
            <button 
              type="submit" 
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Update Product
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProduct;
