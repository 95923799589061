import React from 'react';
import { Link } from 'react-router-dom';

const UnderConstPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-8 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full text-center">
        <h1 className="text-3xl font-bold mb-4">Page Under Construction</h1>
        <p className="mb-4">
          We're working hard to get this page ready. Please check back later.
        </p>
        <div className="flex justify-center">
          <Link to="/products" className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg">
            Go Back Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnderConstPage;
