import React, { useState } from 'react';
import ComponentGallery from '../codeComponents/componentProducts/previewCollectionImages';
import ProductList from './ProductList';
import UserBidsPage from '../BiddingComponets/UsersBid';

const ProductsController = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to manage active tab

  return (
    <div className="bg-gray-900 min-h-screen text-white p-8">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-gradient-black-white">What We Have</h2>

        {/* Tab Buttons */}
        <div className="flex justify-center mb-8">
          <button
            onClick={() => setActiveTab('tab1')}
            className={`px-4 py-2 mx-2 w-1/2 transition-colors duration-300 ${
              activeTab === 'tab1' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
          Projects
          </button>
        
          <button
            onClick={() => setActiveTab('tab2')}
            className={`px-4 py-2 w-1/2 mx-2 transition-colors duration-300 ${
              activeTab === 'tab2' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
       Componets
          </button>

          <button
            onClick={() => setActiveTab('tab3')}
            className={`px-4 py-2 w-1/2 mx-2 transition-colors duration-300 ${
              activeTab === 'tab3' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
      All Bids
          </button>
        </div>

        {/* Tab Content */}
        <div className="text-center">
          {activeTab === 'tab1' && (
            <div>
      <ProductList route={'product'}  content={'all'} route2={'allProducts'}/>
            </div>
          )}
          {activeTab === 'tab2' && (
            <div>
            
        <ComponentGallery route={'code'}  route2={'codes'} content={'all'} />
            </div>
          )}
         {activeTab === 'tab3' && (
            <div>
            
        <UserBidsPage route={"bid"} route2={'bids'} content={'all'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsController;
