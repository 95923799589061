import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaEnvelope, FaShoppingCart, FaCreditCard, FaClock, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { LuCoins } from "react-icons/lu";

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const [usedRefral, setUsedRefrals] = useState(0);
  const [myproducts, setMyProducts] = useState(0);
  const [upi, setUpi] = useState('');
  const [upiStatus, setUpiStatus] = useState('pending');
  const [editingUpi, setEditingUpi] = useState(false);
  const [newUpi, setNewUpi] = useState('');
  const uri = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUserData();
    myproductsTotal();
  }, []);

  useEffect(() => {
    if (userData.refralCode) {
      fetchUserDataRefral();
    }
    if (userData.upi) {
      setUpi(userData.upi);
      setUpiStatus(userData.upiStatus || 'pending');
    }
  }, [userData]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${uri}/auth/findUser`, { withCredentials: true });
      setUserData(response.data.foundUser);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserDataRefral = async () => {
    try {
      const totalRefrals = await axios.get(`${uri}/transactions/usedRefral/${userData.refralCode}`, { withCredentials: true });
      setUsedRefrals(totalRefrals.data);
    } catch (error) {
      console.error('Error fetching user referral data:', error);
    }
  };

  const myproductsTotal = async () => {
    try {
      const totalProducts = await axios.get(`${uri}/transactions/users/TotalPurchasedItems`, { withCredentials: true });
      const usersProduct = totalProducts.data.data;
      setMyProducts(usersProduct);
    } catch (error) {
      console.error('Error fetching user transactions:', error);
    }
  };

  const handleUpiChange = (event) => {
    setNewUpi(event.target.value);
  };

  const handleUpiSubmit = async () => {
    try {
      const response = await axios.put(`${uri}/auth/update`, { upi: newUpi, verification: "pending" }, { withCredentials: true });
      setUserData({ ...userData, upi: response.data.upi });
      setUpi(response.data.upi);
      setUpiStatus('pending');
      setEditingUpi(false);
    } catch (error) {
      console.error('Error updating UPI:', error);
    }
  };

  const renderUpiStatusIcon = () => {
    switch (upiStatus) {
      case 'accepted':
        return <FaCheckCircle className="text-green-500 text-3xl" />;
      case 'rejected':
        return <FaTimesCircle className="text-red-500 text-3xl" />;
      case 'pending':
      default:
        return <FaClock className="text-yellow-500 text-3xl" />;
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white px-4 md:px-8">
      <div className="container mx-auto mt-10 p-6 md:p-8 bg-gray-800 rounded-lg shadow-md">
        <h2 className="text-xl md:text-2xl font-bold mb-8 text-center text-blue-400">User Profile</h2>
        
        {/* Profile Header */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-6 space-y-4 md:space-y-0 md:space-x-6">
          <div className="flex-shrink-0">
            <img className="h-20 w-20 md:h-24 md:w-24 rounded-full object-cover border-4 border-gray-600" src={userData.image} alt="Avatar" />
          </div>
          <div className="ml-0 md:ml-4 text-center md:text-left">
            <h2 className="text-lg md:text-xl font-semibold text-gray-100">{userData.username}</h2>
            <p className="text-sm md:text-base text-gray-400">{userData.email}</p>
          </div>
        </div>

        {/* User Info Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          <div className="border border-gray-700 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <FaUser className="text-2xl text-blue-400 mb-2" />
            <p className="text-lg md:text-xl font-semibold">Username</p>
            <p className="truncate">{userData.username}</p>
          </div>
          <div className="border border-gray-700 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <FaEnvelope className="text-2xl text-blue-400 mb-2" />
            <p className="text-lg md:text-xl font-semibold">Email</p>
            <p className="break-words">{userData.email}</p>
          </div>
          <div className="border border-gray-700 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <FaShoppingCart className="text-2xl text-blue-400 mb-2" />
            <p className="text-lg md:text-xl font-semibold">Total Bought Items</p>
            <p>{myproducts}</p>
          </div>
          <div className="border border-gray-700 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <FaMoneyBill1Wave className="text-2xl text-blue-400 mb-2" />
            <p className="text-lg md:text-xl font-semibold">Total Earnings</p>
            <p>{userData.earnings}</p>
          </div>
          <div className="border border-gray-700 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <LuCoins className="text-2xl text-blue-400 mb-2" />
            <p className="text-lg md:text-xl font-semibold">Total Points Earned</p>
            <p>{usedRefral * 10}</p>
          </div>
        </div>

        {/* Referral Code */}
        <div className="mt-8">
          <h3 className="text-xl md:text-2xl font-semibold mb-2">Referral Code:</h3>
          <p className="text-blue-400">{userData.referralCode}</p>
        </div>

        {/* UPI ID Section */}
        <div className="mt-8">
          <h3 className="text-xl md:text-2xl font-semibold mb-2">UPI ID:</h3>
          <div className="border border-gray-700 rounded-lg p-4 bg-gray-800">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex items-center mb-4 md:mb-0">
                <FaCreditCard className="text-2xl text-blue-400 mr-2" />
                {editingUpi ? (
                  <input
                    type="text"
                    value={newUpi}
                    onChange={handleUpiChange}
                    className="px-4 py-2 rounded-lg border border-gray-700 bg-gray-900 text-white w-full md:w-auto"
                    placeholder="Enter UPI ID"
                  />
                ) : (
                  <p className="truncate text-blue-400">{upi || 'No UPI ID available'}</p>
                )}
              </div>
              <div className="ml-0 md:ml-4 flex items-center">
                {renderUpiStatusIcon()}
                <span className="ml-2 text-gray-400">{upiStatus.charAt(0).toUpperCase() + upiStatus.slice(1)}</span>
              </div>
            </div>

            {editingUpi ? (
              <div className="flex flex-col md:flex-row mt-4">
                <button
                  onClick={handleUpiSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors w-full md:w-auto"
                >
                  Save UPI
                </button>
                <button
                  onClick={() => setEditingUpi(false)}
                  className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors mt-2 md:mt-0 md:ml-4 w-full md:w-auto"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                onClick={() => setEditingUpi(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors mt-4 w-full md:w-auto"
              >
                Edit UPI ID
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
