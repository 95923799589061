import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const SingleProductPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const uri = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [mainImage, setMainImage] = useState(null);

    useEffect(() => {
        const fetchProductById = async () => {
            try {
                const response = await axios.get(`${uri}/product/productById/${id}`, { withCredentials: true });
                setProduct(response.data);
                setMainImage(response.data.image[0]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductById();
    }, [id]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, []);

    const handleImageClick = (image) => {
        setMainImage(image);
    };

    if (loading) {
        return <div className="flex items-center justify-center h-screen text-gray-600">Loading...</div>;
    }

    if (error) {
        return <div className="flex items-center justify-center h-screen text-red-600">Error: {error.message}</div>;
    }

    return (
        <div className="bg-gray-900 min-h-screen text-white">
            <div className="container mx-auto mt-10 p-8 bg-gray-800 rounded-lg shadow-md">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
                    <div>
                        <img src={mainImage} className="w-full h-96 object-contain rounded-lg shadow-md" alt={product.title} />
                        <div className="grid grid-cols-3 gap-4 mt-4">
                            {product.image.map((img, index) => (
                                <div 
                                    key={index} 
                                    className="relative group cursor-pointer"
                                    onClick={() => handleImageClick(img)}
                                >
                                    <img 
                                        src={img} 
                                        className="w-full h-32 object-contain rounded-lg border-2 border-gray-600 shadow-md transform transition-transform duration-300 group-hover:scale-105"
                                        alt={`Product ${index + 1}`} 
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <span className="text-white text-lg font-bold">Preview</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center space-y-6">
                        <h2 className="text-4xl font-semibold text-gray-100">{product.title}</h2>
                        <p className="text-gray-400 text-lg">Deployed Link: <a className='text-blue-400 font-bold' href={product.deployedLink} target="_blank" rel="noopener noreferrer">Click to redirect</a></p>
                        <p className="text-gray-400 text-lg">Created Date: <span className='text-blue-300 font-semibold'>{product.createdDate.split('T')[0]}</span></p>
                        <p className="text-gray-400 text-lg">Price: ₹ <span className='text-green-400'>{product.price}</span></p>
                        <p className="text-gray-400 leading-relaxed">{product.description}</p>
                        <div className="flex justify-center">
                            <button 
                                className="bg-pink-600 text-white px-8 py-3 rounded-full hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50"
                                onClick={() => navigate(`/paymentPage/${product.productId}`)}
                            >
                                Proceed to Buy
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* User Testimonials */}
            <div className="container mx-auto mt-16 p-8 bg-gray-800 rounded-lg shadow-md">
                <h3 className="text-3xl font-semibold mb-8 text-gray-100">What Our Users Say</h3>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" className="w-32 h-32 object-cover mx-auto rounded-full shadow-md mb-4" alt="User 1" />
                        <p className="text-gray-400">"Amazing Code! Highly recommended."</p>
                    </div>
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" className="w-32 h-32 object-cover mx-auto rounded-full shadow-md mb-4" alt="User 2" />
                        <p className="text-gray-400">"This Code is best for portfolio."</p>
                    </div>
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" className="w-32 h-32 object-cover mx-auto rounded-full shadow-md mb-4" alt="User 3" />
                        <p className="text-gray-400">"Fantastic content and great experience."</p>
                    </div>
                    <div className="text-center">
                        <img src="https://via.placeholder.com/150" className="w-32 h-32 object-cover mx-auto rounded-full shadow-md mb-4" alt="User 4" />
                        <p className="text-gray-400">"Best course I've ever taken."</p>
                    </div>
                </div>
            </div>

            {/* Course Description */}
            <div className="container mx-auto mt-16 p-8 bg-gray-800 rounded-lg shadow-md">
                <h3 className="text-3xl font-semibold mb-8 text-gray-100">Course Description</h3>
                <p className="text-gray-400 leading-relaxed">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis condimentum libero non accumsan. Fusce sed sollicitudin metus, ut accumsan sapien. Mauris efficitur eros et dolor placerat, a suscipit nisi commodo. Nulla at mauris a nulla interdum efficitur. Fusce volutpat ultricies justo, sed gravida eros interdum nec. Vestibulum ultrices sapien vel eros ultricies ullamcorper. Vivamus id metus a risus vehicula finibus. Cras pellentesque purus a odio porta ultrices. In non lobortis libero, non laoreet odio. Cras dapibus purus id leo dictum, at bibendum erat condimentum. Nulla facilisi. Sed quis augue sed turpis laoreet accumsan. Donec mollis imperdiet risus, sed sollicitudin sem venenatis vel. Fusce nec mi eget nibh fringilla efficitur.
                </p>
            </div>
        </div>
    );
};

export default SingleProductPage;
