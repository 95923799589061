// src/pages/AdminDashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUsers, FaBoxOpen, FaCogs, FaGavel, FaMoneyCheckAlt, FaChartLine } from 'react-icons/fa';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white p-8">
      <h1 className="text-4xl font-bold mb-12 text-center">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          onClick={() => navigate('/admin/allUsers')}
          className="cursor-pointer bg-blue-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-blue-700"
        >
          <FaUsers className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Users</h2>
          <p className="text-center mt-2">Manage all users</p>
        </div>
        <div
          onClick={() => navigate('/admin/allProjects')}
          className="cursor-pointer bg-green-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-green-700"
        >
          <FaBoxOpen className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Products</h2>
          <p className="text-center mt-2">View and manage products</p>
        </div>
        <div
          onClick={() => navigate('/admin/allComponents')}
          className="cursor-pointer bg-purple-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-purple-700"
        >
          <FaCogs className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Components</h2>
          <p className="text-center mt-2">View and manage components</p>
        </div>
        <div
          onClick={() => navigate('/admin/allBids')}
          className="cursor-pointer bg-orange-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-orange-700"
        >
          <FaGavel className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Bids</h2>
          <p className="text-center mt-2">View and manage bids</p>
        </div>
        <div
          onClick={() => navigate('/admin/ordersTable')}
          className="cursor-pointer bg-red-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-red-700"
        >
          <FaMoneyCheckAlt className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Transactions</h2>
          <p className="text-center mt-2">View all transactions</p>
        </div>
        <div
          onClick={() => navigate('/admin/Profit')}
          className="cursor-pointer bg-yellow-600 p-8 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:bg-yellow-700"
        >
          <FaChartLine className="text-5xl mb-4 mx-auto" />
          <h2 className="text-2xl font-semibold text-center">Profit</h2>
          <p className="text-center mt-2">View profit details</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
