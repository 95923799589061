import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoCopyOutline } from 'react-icons/io5';
import { LiveProvider, LivePreview, LiveError } from 'react-live';
import { useNavigate, useParams } from 'react-router-dom';
import { FaCreditCard, FaLock, FaDesktop, FaMobileAlt, FaTabletAlt } from 'react-icons/fa';

const FaIcons = Object.keys(require('react-icons/fa')).reduce((acc, key) => {
  const Icon = require('react-icons/fa')[key];
  acc[key] = Icon;
  return acc;
}, {});

const backendUrl = process.env.REACT_APP_API_URL;

const ComponentPreview = () => {
  const [jsxCode, setJsxCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [purchase, setPurchase] = useState(false);
  const [screenSize, setScreenSize] = useState('pc');

  const { codeId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkPurchaseStatus = async () => {
      try {
        const response = await axios.get(`${backendUrl}/transactions/${codeId}`, {
          withCredentials: true,
        });
        if (response.data.success) {
          setPurchase(true);
        }
      } catch (error) {
        console.error('Error checking purchase status:', error);
      }
    };

    checkPurchaseStatus();
  }, [backendUrl, codeId]);

  useEffect(() => {
    const styleTag = document.getElementById('dynamic-styles');
    if (styleTag) {
      styleTag.innerHTML = cssCode;
    } else {
      const newStyleTag = document.createElement('style');
      newStyleTag.id = 'dynamic-styles';
      newStyleTag.innerHTML = cssCode;
      document.head.appendChild(newStyleTag);
    }

    return () => {
      const styleTag = document.getElementById('dynamic-styles');
      if (styleTag) {
        styleTag.remove();
      }
    };
  }, [cssCode]);

  useEffect(() => {
    const fetchCode = async () => {
      try {
        const response = await axios.get(`${backendUrl}/code/component/${codeId}`, {
          withCredentials: true,
        });
        setJsxCode(response.data.jsxCode || '');
        setCssCode(response.data.cssCode || '');
      } catch (error) {
        console.error('Error fetching code:', error);
      }
    };

    fetchCode();
  }, [backendUrl, codeId]);

  const handleCopy = (code, type) => {
    navigator.clipboard.writeText(code)
      .then(() => {
        alert(`${type} code copied to clipboard!`);
      })
      .catch((err) => {
        console.error('Failed to copy code:', err);
      });
  };

  const handlePurchase = () => {
    navigate(`/buy/product/${codeId}`);
  };

  const handleScreenSizeChange = (size) => {
    setScreenSize(size);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6 relative">
      <style>
        {`
          ::-webkit-scrollbar {
            display: none;
          }
          .no-scrollbar {
            scrollbar-width: none;
          }
          textarea {
            overflow-y: auto;
            scroll-behavior: smooth;
          }
        `}
      </style>
      
      <div
        className="absolute inset-0 bg-cover bg-center opacity-20 filter blur-sm"
        style={{ backgroundImage: "url('https://source.unsplash.com/random/1920x1080?tech')" }}
      ></div>

      <div className="relative z-10 flex flex-col gap-6">
       
            {/* Component Preview Section */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Component Preview</h2>

              <div className="flex justify-around mb-4">
                <button
                  className={`px-4 py-2 rounded-full ${screenSize === 'pc' ? 'bg-blue-500' : 'bg-gray-700'} hover:bg-blue-400`}
                  onClick={() => handleScreenSizeChange('pc')}
                >
                  <FaDesktop size={20} />
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${screenSize === 'tablet' ? 'bg-blue-500' : 'bg-gray-700'} hover:bg-blue-400`}
                  onClick={() => handleScreenSizeChange('tablet')}
                >
                  <FaTabletAlt size={20} />
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${screenSize === 'mobile' ? 'bg-blue-500' : 'bg-gray-700'} hover:bg-blue-400`}
                  onClick={() => handleScreenSizeChange('mobile')}
                >
                  <FaMobileAlt size={20} />
                </button>
              </div>

              <div className="w-full bg-gray-700 rounded-md ">
                <div
                  className={`preview-container mx-auto bg-gray-900 p-4 rounded-lg 
                  ${screenSize === 'pc' ? 'w-screen-[90vw] h-[80vh]' : ''} 
                  ${screenSize === 'tablet' ? 'w-[90vw] h-[80vh]' : ''} 
                  ${screenSize === 'mobile' ? 'w-[80vw] h-[80vh]' : ''}`}
                >
                  <LiveProvider code={jsxCode} scope={{ React, ...FaIcons }} noInline>
                    <LivePreview />
                    <LiveError />
                  </LiveProvider>
                </div>
              </div>
            </div>

            {/* JSX and CSS Code Editors Section */}
            {purchase || localStorage.getItem('type') === 'admin' ? (
            <div className="flex flex-col md:flex-row gap-6">
              <div className="md:w-1/2 bg-gray-800 rounded-lg shadow-lg p-6 relative no-scrollbar">
                <label className="block text-sm font-medium mb-2" htmlFor="jsxCode">
                  JSX Code
                </label>
                <textarea
                  id="jsxCode"
                  value={jsxCode}
                  onChange={(e) => setJsxCode(e.target.value)}
                  className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Paste your JSX code here..."
                ></textarea>
                <button
                  onClick={() => handleCopy(jsxCode, 'JSX')}
                  className="absolute top-4 right-4 text-blue-500 hover:text-blue-400 transition"
                >
                  <IoCopyOutline size={24} />
                </button>
              </div>

              <div className="md:w-1/2 bg-gray-800 rounded-lg shadow-lg p-6 relative no-scrollbar">
                <label className="block text-sm font-medium mb-2" htmlFor="cssCode">
                  CSS Code
                </label>
                <textarea
                  id="cssCode"
                  value={cssCode}
                  onChange={(e) => setCssCode(e.target.value)}
                  className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Paste your CSS code here..."
                ></textarea>
                <button
                  onClick={() => handleCopy(cssCode, 'CSS')}
                  className="absolute top-4 right-4 text-blue-500 hover:text-blue-400 transition"
                >
                  <IoCopyOutline size={24} />
                </button>
              </div>
            </div>
     
        ) : (
          <div className="md:w-1/2 flex items-center justify-center">
            <div className="bg-white bg-opacity-10 backdrop-blur-lg p-10 rounded-lg shadow-xl flex flex-col items-center">
              <FaLock className="text-6xl text-blue-500 mb-4" />
              <p className="text-lg text-gray-200 mb-6">Unlock this component to access the JSX and CSS code.</p>
              <button
                onClick={handlePurchase}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full flex items-center justify-center gap-2 transition transform hover:scale-105"
              >
                <FaCreditCard size={24} />
                Buy Now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentPreview;
