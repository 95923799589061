import React from 'react';
import Navbar from '../Navbar/Navbar';
import ProductList from '../Product/ProductList';
import Login from '../AuthPages/Login';
import { Routes, Route, useLocation } from "react-router-dom";
import PaymentPage from '../Payment/PaymentPage';
import SingleProductPage from '../Product/BuyProduct';
import MyProducts from '../Product/MyProducts';
import Signup from '../AuthPages/SignUp';
import LandingPage from "../Home/LandingPage";
import AboutUs from "../About/AboutUs";
import AddCourseEventPage from '../Admin/AddProducts';
import Dashboard from "../Admin/DashBoard";
import UpdatePage from "../Admin/UpdateProduct";
import OrdersTable from "../Admin/Transactions";
import DownloadPage from '../Product/BuyedProductView';
import ContactUsPage from './ContactUs';
import ComponentGallery from '../codeComponents/componentProducts/previewCollectionImages';
import ComponentPreview from '../codeComponents/componentProducts/componentPreview';
import UpdateBidStatusPage from '../BiddingComponets/UpdateUserBid';
import BidUploadPage from '../BiddingComponets/DemandUi';
import MultiTabPage from '../Profile/ProfileController';
import CodeUploadPage from '../codeComponents/userUploads/addCode';
import ProductsController from '../Product/ProductController';
import ComponentPaymentPage from '../Payment/ComponentPaymentPage';
import AdminDashboard from '../Admin/AdminDashboard';
import UsersPage from '../Admin/AllUser';
import AllComponents from '../Admin/AllComponents';
import AllProjects from '../Admin/AllProjects';
import AllBidsAdmin from '../Admin/AllBids';
import PurchasesPage from '../Profile/MyPurchase';
import UnderConstPage from '../BiddingComponets/Construction';
import EarnPage from '../codeComponents/userUploads/earnPage';

export default function Home() {
  const location = useLocation();

  // Check if the current route is Login or Signup
  const isLoginPage = location.pathname === '/';
  const isSignupPage = location.pathname === '/signUp';

  // Check if the user exists in localStorage
  const userExists = localStorage.getItem('token');

  // Check if the user is an admin
  const isAdmin = localStorage.getItem("type") === 'admin';

  // Conditionally render the Navbar based on the route
  const renderNavbar = !isLoginPage && !isSignupPage;

  return (
    <div>
      {renderNavbar && (
        <div>
          <Navbar />
        </div>
      )}

      <Routes>
        {/* Routes for non-authenticated users */}
        {!userExists ? (
          <>
            <Route element={<Login />} path='/' />
            <Route element={<Signup />} path='/signUp' />
          </>
        ) : (
          <>
            {/* Routes for authenticated users */}
            <Route element={<Login />} path='/' />
            <Route element={<Signup />} path='/signUp' />
            <Route element={<LandingPage />} path='/home' />
            <Route path="/BuyProduct/:id" element={<SingleProductPage />} />
            <Route path="/paymentPage/:id" element={<PaymentPage />} />
            <Route path="/findProduct" element={<ProductList />} />
            <Route path="/MyProfile" element={<MultiTabPage />} />
            <Route path="/MyProducts" element={<MyProducts />} />
            <Route path='/aboutUs' element={<AboutUs />} />
            <Route path='/addProduct' element={<AddCourseEventPage />} />
            <Route path='/updateProduct/:id' element={<UpdatePage />} />
            <Route path='/viewProduct/:id' element={<DownloadPage />} />
            <Route path='/contactUs' element={<ContactUsPage />} />
            <Route path='/productGallery/:type' element={<ComponentGallery />} />
            <Route path='/preview/:codeId' element={<ComponentPreview />} />
            <Route path='/update/:bidId' element={<UpdateBidStatusPage />} />
            <Route path='/component/getCode' element={<BidUploadPage />} />
            <Route path='/code/createCode' element={<CodeUploadPage />} />
            <Route path='/products' element={<ProductsController />} />
            <Route path='/buy/product/:id' element={<ComponentPaymentPage />} />
            <Route path='/bid/:id' element={<UnderConstPage />} />
            <Route path='/earn' element={<EarnPage />} />


            {/* Profile Routes */}
            <Route path='/user/myPurchases' element={<PurchasesPage />} />

            {isAdmin && (
              <>
                {/* Admin dashboard */}
                <Route path='/admin/adminDashboard' element={<AdminDashboard />} />
                <Route path='/admin/allUsers' element={<UsersPage />} />
                <Route path='/admin/allComponents' element={<AllComponents />} />
                <Route path='/admin/allProjects' element={<AllProjects />} />
                <Route path='/admin/allBids' element={<AllBidsAdmin />} />
                <Route path='/admin/Profit' element={<Dashboard />} />
                <Route path='/admin/ordersTable' element={<OrdersTable />} />
              </>
            )}
          </>
        )}
      </Routes>
    </div>
  );
}
