import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoCopyOutline } from 'react-icons/io5';
import { LiveProvider, LivePreview, LiveError } from 'react-live';
import { useParams } from 'react-router-dom';

// Import all icons dynamically
const FaIcons = Object.keys(require('react-icons/fa')).reduce((acc, key) => {
  const Icon = require('react-icons/fa')[key];
  acc[key] = Icon;
  return acc;
}, {});

const backendUrl = process.env.REACT_APP_API_URL;

const ComponentPreview = () => {
  const [jsxCode, setJsxCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const styleTag = document.getElementById('dynamic-styles');
    if (styleTag) {
      styleTag.innerHTML = cssCode;
    } else {
      const newStyleTag = document.createElement('style');
      newStyleTag.id = 'dynamic-styles';
      newStyleTag.innerHTML = cssCode;
      document.head.appendChild(newStyleTag);
    }

    return () => {
      const styleTag = document.getElementById('dynamic-styles');
      if (styleTag) {
        styleTag.remove();
      }
    };
  }, [cssCode]);

  const { codeId } = useParams();

  useEffect(() => {
    const fetchCode = async () => {
      try {
        const response = await axios.get(`${backendUrl}/code/component/${codeId}`);
        setJsxCode(response.data.jsxCode || '');
        setCssCode(response.data.cssCode || '');
      } catch (error) {
        console.error('Error fetching code:', error);
      }
    };

    fetchCode();
  }, [backendUrl, codeId]);

  const handleCopy = (code, type) => {
    navigator.clipboard.writeText(code)
      .then(() => {
        alert(`${type} code copied to clipboard!`);
      })
      .catch((err) => {
        console.error('Failed to copy code:', err);
      });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <style>
        {`
          /* Hide scrollbar for WebKit browsers */
          ::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for Firefox */
          .no-scrollbar {
            scrollbar-width: none;
          }

          /* Smooth scrolling */
          textarea {
            overflow-y: auto;
            scroll-behavior: smooth;
          }
        `}
      </style>
      <div className="flex flex-col md:flex-row gap-6">
        {/* Code Inputs */}
        <div className="md:w-1/2 flex flex-col gap-6">
          {/* JSX Input */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 relative no-scrollbar">
            <label className="block text-sm font-medium mb-2" htmlFor="jsxCode">
              JSX Code
            </label>
            <textarea
              id="jsxCode"
              value={jsxCode}
              onChange={(e) => setJsxCode(e.target.value)}
              className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Paste your JSX code here..."
            ></textarea>
            <button
              onClick={() => handleCopy(jsxCode, 'JSX')}
              className="absolute top-4 right-4 text-blue-500 hover:text-blue-400 transition"
            >
              <IoCopyOutline size={24} />
            </button>
          </div>

          {/* CSS Input */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 relative no-scrollbar">
            <label className="block text-sm font-medium mb-2" htmlFor="cssCode">
              CSS Code
            </label>
            <textarea
              id="cssCode"
              value={cssCode}
              onChange={(e) => setCssCode(e.target.value)}
              className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Paste your CSS code here..."
            ></textarea>
            <button
              onClick={() => handleCopy(cssCode, 'CSS')}
              className="absolute top-4 right-4 text-blue-500 hover:text-blue-400 transition"
            >
              <IoCopyOutline size={24} />
            </button>
          </div>
        </div>

        {/* Component Preview */}
        <div className="md:w-1/2 bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-4">Component Preview</h2>
          <div className="w-full bg-gray-700 rounded-md p-4 mb-4">
            <LiveProvider code={jsxCode} scope={{ React, ...FaIcons }} noInline>
              <div className="w-full">
                <LivePreview />
                <LiveError className="text-red-500 mt-4" />
              </div>
            </LiveProvider>
            {error && <div className="text-red-500 mt-4">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentPreview;
