import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Modal from "../Animations/Congragulation";

const ComponentPaymentPage = () => {
  const [loading, setLoading] = useState(false);
  const [refral, setRefral] = useState('');
  const [rStatus, setrStatus] = useState("0");
  const [price, setPrice] = useState();
  
  const uri = process.env.REACT_APP_API_URL;
  const Key = process.env.REACT_APP_PAYMENT_KEY;
  const [product, setProduct] = useState();
  const [userData, setUserData] = useState('');
  const [showModal, setShowModal] = useState(false);

  const {id}  = useParams()
  useEffect(() => {
    fetchProductById();
    fetchUserData();
  }, []);

  const fetchProductById = async () => {
    try {
      const response = await axios.get(`${uri}/code/component/${id}`, { withCredentials: true });
      setProduct(response.data);
      setPrice(response.data.price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyReferral = async () => {
    try {
      const code = await axios.post(`${uri}/auth/findRefral`, { refralCode: refral }, { withCredentials: true });
      if (code.status === 200) {
        setrStatus("1");
        setPrice(product.price - (product.price * 20) / 100);
      } else {
        setrStatus('2');
        setPrice(product.price);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${uri}/auth/findUser`, { withCredentials: true });
      setUserData(response.data.foundUser);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const paymentSuccessHandle = async (response) => {
    try {
      
      const res = await axios.post(`${uri}/transactions/paymentSuccess`, { response, id:product.codeId, product, price, refral,category:"component" }, { withCredentials: true });
      setShowModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      console.log(price,"price")
      const response = await axios.post(`${uri}/transactions/initate`, { amount:price ,data:"payment" }, { withCredentials: true });
      const { orderId } = response.data;
      const options = {
        key: Key,
        amount: price * 100,
        currency: 'INR',
        name: 'COOOUU',
        description: 'Product Purchase',
        order_id: orderId,
        handler: function (response) {
          axios.post(`${uri}/transactions/sendRecepit`, {}, { withCredentials: true });
          paymentSuccessHandle(response);
          setLoading(false);
        },
        prefill: {
          name: userData.username,
          email: userData.email,
          contact: ''
        },
        theme: {
          color: '#1E293B'
        }
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error processing payment:', error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-8">
      <div className="w-full max-w-lg bg-gray-800 rounded-lg shadow-lg p-8">
        {product && (
          <div className="mb-6">
            <h1 className="text-3xl font-semibold mb-4 text-indigo-400">
              Total Amount: ₹{rStatus === "1" ? price : product.price}
            </h1>
          </div>
        )}

        <div className="mb-6">
          <label htmlFor="referral" className="block text-sm font-medium mb-2">
            Have A Referral Code?
          </label>
          <div className="flex items-center">
            <input 
              type="text" 
              id="referral" 
              className="flex-1 p-3 rounded-l-lg bg-gray-700 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-300"
              value={refral}
              onChange={(e) => setRefral(e.target.value)}
            />
            <button 
              className="ml-2 px-4 py-3 bg-indigo-600 text-sm font-medium rounded-r-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={handleApplyReferral}
            >
              Apply
            </button>
          </div>
          {rStatus === "1" && <p className="text-green-500 mt-2">Code Applied Successfully!</p>}
          {rStatus === "2" && <p className="text-red-500 mt-2">Invalid Referral Code</p>}
        </div>

        <button
          className="w-full py-3 bg-indigo-600 text-base font-medium rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={handlePayment}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>

        {loading && (
          <div className="mt-4 flex justify-center">
            <div className="loader"></div>
          </div>
        )}

        {showModal && <Modal onClose={() => setShowModal(false)} />}  
      </div>
    </div>
  );
};

export default ComponentPaymentPage;
