import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LeftImage from "../../assests/login.jpeg";

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

useEffect(()=>{


const authorizeUser = async()=>{

try{


const response = await  axios.get(`${backendUrl}/auth/verify`,{withCredentials:true})

if(response.status == "200"){
  navigate('/home')
}

}catch(error){
  console.error(error)
}


}

authorizeUser();

},[])




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${backendUrl}/auth/login`, formData, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const token = response.data.accessToken;
        const type = response.data.type;

        localStorage.setItem("type", type);
        localStorage.setItem("token", token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        navigate('/home');
      } else {
        setInvalidCredentials(true);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const isFormValid = () => {
    return formData.email !== '' && formData.password !== '';
  };

  useEffect(() => {
    const user = localStorage.getItem("type");
    setUser(user);
  }, []);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white">
      {/* Loading Spinner */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
            <p className="mt-4 text-white">Server is getting started...</p>
          </div>
        </div>
      )}

      <div className={`w-full max-w-screen-md bg-gray-800 rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row ${loading ? 'blur-sm' : ''}`}>
        {/* Left Image */}
        <div className="hidden md:block relative w-1/2">
          <img
            src={LeftImage}
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>

        {/* Login Form */}
        <div className="p-6 md:p-12 w-full md:w-1/2 flex flex-col justify-center relative">
          <h1 className="text-2xl text-gradient-black-white md:text-3xl font-bold mb-6 text-center">Login to Your Account</h1>
          {invalidCredentials && (
            <p className="text-red-500 mb-4 text-center">* Invalid email or password</p>
          )}

          {/* Sign Up Link - Top Right Corner */}
          <p className="absolute top-4 right-4 text-sm text-gray-400">
            Not a member yet?{' '}
            <span
              onClick={() => navigate('/signUp')}
              className="text-blue-400 cursor-pointer font-semibold"
            >
              Sign up
            </span>
          </p>

          {/* Form */}
          <form className="space-y-4 w-full" onSubmit={handleSubmit}>
            {/* Email */}
            <div className="flex flex-col w-full">
              <label htmlFor="email" className="text-sm font-semibold mb-2">Email</label>
              <input
                onChange={handleChange}
                type="email"
                name="email"
                id="email"
                className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full"
              />
            </div>

            {/* Password */}
            <div className="flex flex-col w-full">
              <label htmlFor="password" className="text-sm font-semibold mb-2">Password</label>
              <input
                onChange={handleChange}
                type="password"
                name="password"
                id="password"
                className="border border-gray-600 bg-gray-900 p-2 rounded-md focus:outline-none focus:border-blue-400 w-full"
              />
            </div>

            {/* Login Button */}
            <button
              type="submit"
              className={`bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition-colors w-full mt-4 ${!isFormValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!isFormValid()}
            >
              Login
            </button>
          </form>

          {/* Google Protection and Policy */}
          <p className="text-center text-gray-400 mt-4 text-xs">
            This site is protected by reCAPTCHA and the Google{' '}
            <span className="text-blue-400">Privacy Policy</span> and{' '}
            <span className="text-blue-400">Terms of Service</span> apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
