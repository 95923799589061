import React from 'react';

const FooterPage = () => {
  return (
    <div className="bg-black text-white p-16">
      <div className="flex flex-col md:flex-row justify-between">
        {/* Logo and Company Description */}
        <div className="w-full md:w-1/3 mb-8 md:mb-0">
          <h1 className='text-2xl text-gradient mb-2'>CodeCells</h1>
          <p className="text-sm">
            CodeCells is a platform where you are able to upload images of the ui and out tem will genrate the code for the ui as required with charges of pages and ui with our team </p>
        </div>
        
        {/* Explore */}
        <div className="w-full md:w-1/5 mb-8 md:mb-0">
          <h3 className="font-bold mb-4">Explore</h3>
          <ul className="space-y-2">
            <li><a href="#feature1" className="text-sm">Product</a></li>
            <li><a href="#feature2" className="text-sm">Pricing</a></li>
            <li><a href="#feature3" className="text-sm">Customer Service</a></li>
            <li><a href="#feature4" className="text-sm">Career</a></li>
          </ul>
        </div>

        {/* Learn */}
        <div className="w-full md:w-1/5 mb-8 md:mb-0">
          <h3 className="font-bold mb-4">Learn</h3>
          <ul className="space-y-2">
            <li><a href="#blog" className="text-sm">About Us</a></li>
            <li><a href="#resources" className="text-sm">Blog</a></li>
          </ul>
        </div>

        {/* Contact */}
        <div className="w-full md:w-1/5 mb-8 md:mb-0">
          <h3 className="font-bold mb-4">Contact</h3>
          <ul className="space-y-2">
            <li className="text-sm">info@codecells.com</li>
            <li className="text-sm">Registered Office:
1507, xxx, 11th cross road, 19th Main Road, xxxx, India. 00000</li>
        
          </ul>
        </div>
      </div>

      {/* Footer Links and Copyright */}
      <div className="mt-8 border-t border-gray-700 justify-between pt-4 py-14 flex flex-col md:flex-row w-1/2 items-center">
        <p className="text-xs">Copyright 2024 CodeCells</p>
        <p className="text-xs"> Terms of Service</p>
        <p className="text-xs"> Privacy Policy</p>

     
      </div>
      <div>
        <p>
        Sahil Mulani Pvt ltd<br />
CIN: XXXXXXXXXXXXXXXXXXX
        </p>
      </div>
    </div>
  );
};

export default FooterPage;