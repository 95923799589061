import React from 'react';

const Testimonials = () => {
  return (
    <section className="bg-black text-white py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold mb-12">What Our Customers Say</h2>
        <div className="flex flex-col md:flex-row justify-center gap-8">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <p className="text-lg mb-4">
              "Amazing quality components! Highly recommended!"
            </p>
            <p className="text-sm font-semibold text-gray-300">- John Doe</p>
          </div>
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <p className="text-lg mb-4">
              "Great customer service and quick support!"
            </p>
            <p className="text-sm font-semibold text-gray-300">- Jane Smith</p>
          </div>
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <p className="text-lg mb-4">
              "Efficient and easy to use components!"
            </p>
            <p className="text-sm font-semibold text-gray-300">- Emily Brown</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
