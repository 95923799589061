import React, { useState } from 'react';
import ProductList from '../Product/ProductList';
import ComponentGallery from '../codeComponents/componentProducts/previewCollectionImages';
import UserBidsPage from '../BiddingComponets/UsersBid';
import ImageGallery from '../Purchases/ComponentPurchasedUser';
import ProjectImageGallery from '../Purchases/ProjectPurchasedUser';

const PurchasesPage = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to manage active tab

  return (
    <div className="bg-gray-900 min-h-screen text-white p-4 sm:p-8 lg:p-12">
      <div className="container mx-auto">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 sm:mb-8 lg:mb-10 text-center text-gradient-black-white">My Purchases</h2>

        {/* Tab Buttons */}
        <div className="flex flex-wrap justify-center mb-6 sm:mb-8 lg:mb-10">
          <button
            onClick={() => setActiveTab('tab1')}
            className={`px-3 py-1 text-sm sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/3 ${
              activeTab === 'tab1' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            Products
          </button>
          <button
            onClick={() => setActiveTab('tab2')}
            className={`px-3 py-1 text-sm sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/3 ${
              activeTab === 'tab2' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            Bids
          </button>
          <button
            onClick={() => setActiveTab('tab3')}
            className={`px-3 py-1 text-sm sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/3 ${
              activeTab === 'tab3' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            Components
          </button>
        </div>

        {/* Tab Content */}
        <div className="text-center">
          {activeTab === 'tab1' && (
            <div className="px-4">
                {/* <ProductList route={'transactions'} route2={'products'} content={'project'} /> */}
          <ProjectImageGallery />
            </div>
          )}
          {activeTab === 'tab2' && (
            <div className="px-4">
<UserBidsPage route={'transactions'} route2={'bids'} content={'bid'}  />
            </div>
          )}
          {activeTab === 'tab3' && (
            <div className="px-4">
                {/* <ComponentGallery route={'transactions'} route2={'code'} content={'component'} /> */}
            <ImageGallery />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchasesPage;
