import React from 'react';
import Founder from "../../assests/profile.jpg"; // Replace with actual image path

const FounderPage = () => {
  return (
    <div className="min-h-screen bg-black text-white flex flex-col justify-center items-center p-8">
      <div className="w-full max-w-6xl flex flex-col md:flex-row items-center">
        {/* Left Side: Image */}
        <div className="w-full md:w-1/2 relative">
          <img
            src={Founder}
            alt="Founders"
            className="w-full h-auto object-cover rounded-lg shadow-2xl"
          />
          <div className="absolute bottom-4 right-4 bg-white text-gray-800 text-sm p-4 rounded-lg shadow-lg">
            <p className="font-semibold">Sahil Mulani</p>
            <p className="italic">-- Founder</p>
          </div>
        </div>

        {/* Right Side: Text */}
        <div className="w-full md:w-1/2 flex flex-col justify-center items-start p-8 space-y-6">
          <h1 className="text-3xl text-gradient-black-white md:text-4xl font-bold mb-4">From the Founder</h1>
          <p className="text-lg">
            We are a young company solving an age-old problem: Supply Chain. Our goal is simple: to eradicate stock outs for businesses that want to grow faster. Our background, tech expertise, and empathy allow us to do just that.
          </p>
          <p className="text-lg">
            Thank you for checking out Crest, and we hope to help you always be in stock!
          </p>
          <button className="bg-white text-black border-2 border-white px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition-colors">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default FounderPage;
