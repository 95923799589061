
// import CodeUploadPage from "./Components/userUploads/addCode";
import AllBids from "./Components/BiddingComponets/AllComponentsBid";
import BidUploadPage from "./Components/BiddingComponets/DemandUi";
import UploadPage from "./Components/BiddingComponets/DemandUi";
import UpdateBidStatusPage from "./Components/BiddingComponets/UpdateUserBid";
import UserBidsPage from "./Components/BiddingComponets/UsersBid";
import Home from "./Components/Home/Home"
import CounterLivePage from "./Components/Practice/ReactLive";
function App() {
  return (
    <div className="bg-black">
  <Home />






  {/* Practice Part */}
{/* <CounterLivePage /> */}
    </div>
  );
}

export default App;
