import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const ComponentGallery = ({ content, route, route2 }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_API_URL; // Ensure you have this set up
  const navigate = useNavigate();
  const { type } = useParams();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        let response;
        let fetchedData;
        if (content) {
          response = await axios.get(`${backendUrl}/${route}/${route2}/${content}`, {
            withCredentials: true,
          });
          fetchedData = response.data.data;
        } else if (type) {
          response = await axios.get(`${backendUrl}/code/componentByName/${type}`, {
            withCredentials: true,
          });
          fetchedData = response.data;
        }

        // If the fetched data is not an array, convert it to an array
        if (!Array.isArray(fetchedData)) {
          fetchedData = [fetchedData];
        }

        setImages(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, [backendUrl, type, content]);

  const handlePreview = (codeId) => {
    navigate(`/preview/${codeId}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 md:p-6">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8">
        <span className="text-gradient">{type}</span> <span className="text-gradient-black-white">{content} Component Gallery</span>
      </h1>
      <div className="flex flex-col space-y-6">
        {images && images.map((image, index) => (
          <div key={index} className="bg-gray-800 rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row items-start md:items-center p-4 md:space-x-4 transition-transform transform hover:scale-105">
            <div className="w-full md:w-2/3 flex items-center justify-center">
              <img src={image.imageUrl} alt={`Component ${index + 1}`} className="w-full h-48 object-contain rounded-3xl shadow-md" />
            </div>
            <div className="w-full md:w-1/3 p-4 flex flex-col justify-between bg-gray-700 rounded-lg md:rounded-r-lg">
              <h2 className="text-lg font-semibold mb-2">{image.componentName}</h2>
              <p className="text-gray-300 mb-2">creator:<span className='text-green-400 font-extrabold text-xl'>  {image.user}</span> </p>
              <p className="text-gray-300 mb-2">Type: {image.codeType}</p>
              <p className="text-gray-300 mb-4">Price: ${image.price}</p>
              <button
                onClick={() => handlePreview(image.codeId)}
                className="py-2 px-4 bg-green-400 hover:bg-green-500 text-white font-bold rounded-md shadow-lg transition duration-300 transform hover:scale-105"
              >
                Preview & Buy
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComponentGallery;
