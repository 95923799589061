import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const MyProducts = () => {
  const [productIds, setProductIds] = useState([]);
  const [products, setProducts] = useState([]);
  const uri = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const fetchProducts = async (ids) => {
    try {
      const productPromises = ids.map(productId =>
        axios.get(`${uri}/product/productById/${productId}`, { withCredentials: true })
      );
      const productResponses = await Promise.all(productPromises);
      const productsData = productResponses.map(response => response.data);
      return productsData;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    const fetchProductIds = async () => {
      try {
        const response = await axios.get(`${uri}/product/myProducts`, { withCredentials: true });
        if (response && response.data) {
          const ids = response.data.map(product => product.ProductId);
          setProductIds(ids);
          const fetchedProducts = await fetchProducts(ids);
          setProducts(fetchedProducts);
        }
      } catch (error) {
        console.error("Error fetching product IDs:", error);
      }
    };

    fetchProductIds();
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="container mx-auto mt-10">
        <h2 className="text-4xl font-bold mb-8 text-center text-gradient-black-white">My Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div key={index} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              <img src={product.image[0]} className="w-full h-48 object-cover" alt='Product' />
              <div className="p-6">
                <h5 className="text-2xl font-semibold mb-2">{product.title}</h5>
                <p className="text-gray-400 text-lg mb-2">Price: Rs {product.price}</p>
                <p className="text-gray-400 text-lg mb-2">Rating: 4.5/5</p>
                <p className="text-gray-400 text-lg mb-4">Seller: {product.seller}</p>
                <button 
                  onClick={() => navigate(`/viewProduct/${product.productId}`)} 
                  className="bg-white text-gray-900 px-6 py-2 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50"
                >
                  <FaEye className="mr-2" />
                  View Product
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyProducts;
