import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductCarousel.css'; // Import custom CSS for additional styles
import { useNavigate } from 'react-router-dom';
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
const backendUrl = process.env.REACT_APP_API_URL; // Replace with your backend URL

const Carousel = ({ title }) => {
 
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/code/componentByName/${title}`,{
          withCredentials:true
        })
        setProducts(response.data.slice(0, 5)); // Limit to max 5 images
        
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [title]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
  };

  return (
    <section className="bg-black text-white py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">{title}</h2>
        <div className="relative w-full max-w-4xl mx-auto overflow-hidden">
          <div className="carousel-container relative">
            <div
              className="carousel-inner flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {products.map((product) => (
                <div
                  key={product._id}
                  className="carousel-item flex-shrink-0 w-full flex items-center object-cover justify-center"
                >
                  <div className="product-card bg-black text-black rounded-lg shadow-lg">
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="w-full h-full  rounded-lg"
                    />
                    {/* <div className="absolute bottom-0 left-0 w-full p-2 bg-black bg-opacity-75 rounded-b-lg">
                      <h3 className="text-lg font-semibold">{product.name}</h3>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handlePrev}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 px-4 py-2 bg-white text-black rounded-full shadow-lg hover:bg-gray-300"
            >
              <GrFormPrevious />
            </button>
            <button
              onClick={handleNext}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 px-4 py-2 bg-white text-black rounded-full shadow-lg hover:bg-gray-300"
            >
              <MdNavigateNext  />
            </button>
          </div>
          <button 
          onClick={()=>navigate(`/productGallery/${title}`)}
          className="mt-8 px-6 py-3 bg-white text-black text-gradient border-3 rounded-full shadow-lg hover:bg-gray-300">
            View More
          </button>
     
        </div>
      </div>
    </section>
  );
};

export default Carousel;
