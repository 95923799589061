import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-grey-500 text-white p-4 text-center mt-auto">
      <p className="text-lg">&copy; 2024 Code Components Store</p>
    </footer>
  );
};

export default Footer;
