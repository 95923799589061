import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import profile from '../../assests/profile.jpg'
const ContactUsPage = () => {
    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <div className="container mx-auto px-4 md:px-8 py-12">
                <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-center text-gradient-black-white">Contact Us</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div className="text-center">
                        <img 
                            src={profile} // Add an image URL if available
                            alt="Site Owner" 
                            className="w-48 h-48 md:w-64 md:h-64 object-cover rounded-full mx-auto mb-6 border-4 border-gray-700" 
                        />
                        <h2 className="text-xl font-semibold mb-2">Sahil Mulani</h2>
                        <p className="text-gray-400">Site Owner</p>
                        <p className="text-gray-400">Open to Work</p>
                    </div>

                    <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                        <ul className="text-lg leading-loose">
                            <li className="mb-4 flex items-center">
                                <FaPhoneAlt className="text-blue-400 mr-4" />
                                <span className="font-semibold text-gray-300">Phone:</span> +91 8432165190 
                            </li>
                            <li className="mb-4 flex items-center">
                                <FaEnvelope className="text-red-400 mr-4" />
                                <span className="font-semibold text-gray-300">Email:</span> sahilmulanioneplus@gmail.com
                            </li>
                            <li className="mb-4 flex items-center">
                                <FaMapMarkerAlt className="text-green-400 mr-4" />
                                <span className="font-semibold text-gray-300">Address:</span> Pune, Maharashtra, INDIA
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
