import React, { useState } from 'react';
import UserProfile from './Profile';
import UserBidsPage from '../BiddingComponets/UsersBid';
import ComponentGallery from '../codeComponents/componentProducts/previewCollectionImages';
import ProductList from '../Product/ProductList';

const MultiTabPage = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to manage active tab

  return (
    <div className="bg-gray-900 min-h-screen text-white p-4 sm:p-8 lg:p-12">
      <div className="container mx-auto">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 sm:mb-8 lg:mb-10 text-center text-gradient-black-white">My Profile</h2>

        {/* Tab Buttons */}
        <div className="flex  justify-center mb-6 sm:mb-8 lg:mb-10">
          <button
            onClick={() => setActiveTab('tab1')}
            className={`px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/4 ${
              activeTab === 'tab1' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            Profile
          </button>
          <button
            onClick={() => setActiveTab('tab2')}
            className={`px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/4 ${
              activeTab === 'tab2' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            My Components
          </button>
          <button
            onClick={() => setActiveTab('tab3')}
            className={`px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/4 ${
              activeTab === 'tab3' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            My Bids
          </button>
          <button
            onClick={() => setActiveTab('tab4')}
            className={`px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-base lg:px-6 lg:py-3 lg:text-lg transition-colors duration-300 w-full sm:w-1/4 ${
              activeTab === 'tab4' ? 'border-b-2 border-blue-400 text-blue-400' : 'text-gray-400'
            }`}
            style={{ background: 'transparent' }}
          >
            My Projects
          </button>
        </div>

        {/* Tab Content */}
        <div className="text-center">
          {activeTab === 'tab1' && (
            <div className="px-4">
              <UserProfile />
            </div>
          )}
          {activeTab === 'tab2' && (
            <div className="px-4">
              <ComponentGallery route={'code'} route2={'codes'} content={"user"} />
            </div>
          )}
          {activeTab === 'tab3' && (
            <div className="px-4">
              <UserBidsPage route={'bid'} route2={'bids'} content={'user'} />
            </div>
          )}
          {activeTab === 'tab4' && (
            <div className="px-4">
              <ProductList route={"product"} route2={'allProducts'}  content={'user'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiTabPage;
