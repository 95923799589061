import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import UpdateModal from './userUpdateModal'; // Adjust the path if needed

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_API_URL;

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/auth/allUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [backendUrl]);

  const handleVerificationUpdate = async (username, newStatus, newEarnings) => {
    try {
      console.log(username, newStatus, newEarnings); // Debugging line
      await axios.put(
        `${backendUrl}/auth/update`,
        { username, verification: newStatus, earnings: newEarnings },
        { withCredentials: true }
      );
      fetchUsers(); // Refresh data after update
    } catch (error) {
      console.error('Error updating verification status:', error);
    }
  };

  const filteredUsers = users.filter((user) => {
    if (filter === 'all') return true;
    return user.verification === filter;
  });

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 md:p-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Users</h1>

      {/* Filter Buttons */}
      <div className="flex flex-wrap justify-center space-y-2 md:space-y-0 space-x-0 md:space-x-4 mb-8">
        <button
          className={`w-full md:w-auto px-4 py-2 rounded-lg ${filter === 'all' ? 'bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'}`}
          onClick={() => setFilter('all')}
        >
          All
        </button>
        <button
          className={`w-full md:w-auto px-4 py-2 rounded-lg ${filter === 'pending' ? 'bg-yellow-500' : 'bg-gray-700 hover:bg-gray-600'}`}
          onClick={() => setFilter('pending')}
        >
          Pending
        </button>
        <button
          className={`w-full md:w-auto px-4 py-2 rounded-lg ${filter === 'accepted' ? 'bg-green-500' : 'bg-gray-700 hover:bg-gray-600'}`}
          onClick={() => setFilter('accepted')}
        >
          Accepted
        </button>
        <button
          className={`w-full md:w-auto px-4 py-2 rounded-lg ${filter === 'rejected' ? 'bg-red-500' : 'bg-gray-700 hover:bg-gray-600'}`}
          onClick={() => setFilter('rejected')}
        >
          Rejected
        </button>
      </div>

      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center min-h-[50vh]">
          <div className="spinner-border text-white animate-spin h-12 w-12 rounded-full border-4 border-gray-300 border-t-white"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="text-left text-sm bg-gray-700">
                <th className="p-4">Profile</th>
                <th className="p-4">Full Name</th>
                <th className="p-4">Username</th>
                <th className="p-4">Referral Code</th>
                <th className="p-4">UPI ID</th>
                <th className="p-4">Earnings</th>
                <th className="p-4">Verification</th>
                <th className="p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr key={user.id} className="border-b border-gray-700 hover:bg-gray-700 transition-colors">
                    <td className="p-4">
                      <img src={user.image} alt={user.username} className="w-16 h-16 rounded-full object-cover" />
                    </td>
                    <td className="p-4">
                      {user.fName} {user.lName}
                    </td>
                    <td className="p-4">{user.username}</td>
                    <td className="p-4">{user.refralCode}</td>
                    <td className="p-4">{user.upi || <span className="text-red-400">No UPI ID</span>}</td>
                    <td className="p-4">{user.earnings}</td>

                    <td className="p-4">
                      {user.verification === 'accepted' ? (
                        <span className="text-green-400 flex items-center">
                          <FaCheckCircle className="mr-2" /> Verified
                        </span>
                      ) : user.verification === 'rejected' ? (
                        <span className="text-red-400 flex items-center">
                          <FaTimesCircle className="mr-2" /> Rejected
                        </span>
                      ) : (
                        <span className="text-yellow-400">Pending</span>
                      )}
                    </td>
                    <td className="p-4 flex space-x-4">
                      <button
                        className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg text-sm font-medium"
                        onClick={() => {
                          setSelectedUser(user);
                          setModalIsOpen(true);
                        }}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="p-4 text-center">
                    No users found for this filter.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {selectedUser && (
        <UpdateModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          user={selectedUser}
          onUpdate={handleVerificationUpdate}
        />
      )}
    </div>
  );
};

export default UsersPage;
