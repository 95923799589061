import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_API_URL;

const ProjectImageGallery = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${backendUrl}/transactions/allPruchases/project`, {
          withCredentials: true,
        });

        const productIds = response.data.data.map((product) => product.ProductId);
        
        const productDetailRequests = productIds.map((id) =>
          axios.get(`${backendUrl}/product/productById/${id}`, { withCredentials: true })
        );

        const productDetails = await Promise.all(productDetailRequests);
        const formattedProducts = productDetails.map((response) => response.data);

        setProducts(formattedProducts); // Store detailed product data
        setLoading(false);
      } catch (err) {
        console.error('Error fetching product details:', err);
        setError('Failed to load product details.');
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, []);

  const [currentSlide, setCurrentSlide] = useState({});

  const handlePrev = (productId) => {
    setCurrentSlide((prevSlide) => ({
      ...prevSlide,
      [productId]: prevSlide[productId] > 0 ? prevSlide[productId] - 1 : 3,
    }));
  };

  const handleNext = (productId) => {
    setCurrentSlide((prevSlide) => ({
      ...prevSlide,
      [productId]: prevSlide[productId] < 3 ? prevSlide[productId] + 1 : 0,
    }));
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <FaSpinner className="animate-spin text-5xl" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-10">
      <h1 className="text-4xl font-bold text-center text-white mb-10">Project Purchase Image Gallery</h1>
      <div className="space-y-10">
        {products.map((product) => (
          <div
            key={product.ProductId}
            className="relative group bg-gray-800 rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row items-center"
          >
            <div className="relative w-full md:w-1/2 h-80">
              {product.image && product.image.length > 0 ? (
                <div className="relative h-full">
                  <img
                    src={product.image[currentSlide[product.ProductId] || 0]}
                    alt={`Product ${product.ProductId}`}
                    className="w-full h-full object-contain transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 flex items-center justify-between px-4">
                    <button
                      className="text-white bg-gray-700 p-2 rounded-full opacity-75 hover:opacity-100"
                      onClick={() => handlePrev(product.ProductId)}
                    >
                      <MdArrowBack className="text-3xl" />
                    </button>
                    <button
                      className="text-white bg-gray-700 p-2 rounded-full opacity-75 hover:opacity-100"
                      onClick={() => handleNext(product.productId)}
                    >
                      <MdArrowForward className="text-3xl" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-700 text-white">
                  No Image Available
                </div>
              )}
            </div>
            <div className="md:w-1/2 p-5 text-white">
              <h2 className="text-2xl font-semibold mb-3">Product {product.productId}</h2>
              <p className="text-sm mb-3 font-bold">{product.title}</p>
              <p className="text-sm mb-3">{product.description}</p> {/* Displaying description */}
              <button
                onClick={() => navigate(`/BuyProduct/${product.productId}`)}
                className="mt-4 py-2 px-6 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              >
                View Details
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectImageGallery;
