import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UpdateBidStatusPage = () => {
  const { bidId } = useParams();
  const [bid, setBid] = useState(null);
  const [userStatus, setUserStatus] = useState('');
  const [budget, setBudget] = useState('');
  const [description, setDescription] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBid = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/bid/bids/${bidId}`,{
          withCredentials:true
        });
        setBid(response.data.data);
        setUserStatus(response.data.data.UserStatus);
        setBudget(response.data.data.budget);
        setDescription(response.data.data.description);
        setCompletionDate(response.data.data.completionDate || ''); // Initialize completionDate
      } catch (error) {
        setError('Failed to fetch bid details.');
      } finally {
        setLoading(false);
      }
    };

    fetchBid();
  }, [bidId]);

  const handleStatusUpdate = async () => {
    if (window.confirm('Are you sure you want to update the bid status?')) {
      try {
        setIsUpdating(true);
        const updatedData = { userStatus, completionDate };

        // Include budget and description if adminStatus is not 'Completed'
        if (bid.AdminStatus !== 'Completed') {
          updatedData.budget = budget;
          updatedData.description = description;
        }

        const response = await axios.put(`${BACKEND_URL}/bid/bids/${bidId}`, updatedData,{
          withCredentials:true
        });
        setResponseMessage(response.data.message || 'Bid updated successfully!');
        // Optionally, you can redirect to a different page or refresh
        setTimeout(() => {
          navigate('/MyProfile'); // Redirect to the bids page
        }, 2000); // Wait for 2 seconds to show the response message
      } catch (error) {
        setError('Failed to update status.');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Update Bid Status</h1>

      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <div className="animate-spin w-16 h-16 border-4 border-t-4 border-gray-600 border-t-blue-500 rounded-full"></div>
        </div>
      ) : error ? (
        <div className="text-red-400 text-center">{error}</div>
      ) : (
        <div className="max-w-lg mx-auto bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
          {bid && (
            <>
              <h2 className="text-2xl font-semibold mb-4">Bid: ${bid.budget}</h2>
              <p className="text-lg mb-4"> User Status: {bid.UserStatus}</p>
              <p className="text-lg mb-4">Admin Status: {bid.AdminStatus}</p>

              {/* Editable fields only if adminStatus is not 'Completed' */}
              {bid.AdminStatus !== 'Completed' && (
                <>
                  <label htmlFor="budget" className="block mb-2">Update Budget:</label>
                  <input
                    type="number"
                    id="budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    className="w-full p-2 bg-gray-700 text-white rounded-md mb-4"
                  />

                  <label htmlFor="description" className="block mb-2">Update Description:</label>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 bg-gray-700 text-white rounded-md"
                  ></textarea>
                </>
              )}

              <label htmlFor="status" className="block mb-2 mt-4">Update User Status:</label>
              <select
                id="status"
                value={userStatus}
                onChange={(e) => setUserStatus(e.target.value)}
                className="w-full p-2 bg-gray-700 text-white rounded-md"
              >
                <option value="waiting">Waiting</option>
                <option value="open">Open</option>
                <option value="close">Close</option>
              </select>

              <label htmlFor="completionDate" className="block mb-2 mt-4">Completion Date:</label>
              <input
                type="date"
                id="completionDate"
                value={completionDate}
                onChange={(e) => setCompletionDate(e.target.value)}
                className="w-full p-2 bg-gray-700 text-white rounded-md mb-4"
              />

              <button
                onClick={handleStatusUpdate}
                className={`mt-4 ${isUpdating ? 'bg-gray-600' : 'bg-green-600'} text-white px-4 py-2 rounded-md text-sm hover:${isUpdating ? 'bg-gray-500' : 'bg-green-500'} transition-colors`}
                disabled={isUpdating}
              >
                {isUpdating ? 'Updating...' : 'Update Status'}
              </button>

              {responseMessage && (
                <div className="mt-4 text-green-400 text-center">{responseMessage}</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateBidStatusPage;
