import React from 'react';
import Features from './Features'
import Teastimonals from './Testimonals'
import FoundersPage from "./Founders"
import Footer from "./Footer"
import Hero from "./HeroPage"
import './Style.css';
import HomePage from "./WhatWeDo"
import VirtualScroller from '../Stat/Stat';
import ProductCarousel from "../ViewProducts/FormsCar"
import AddSection from './AddSection';
function App() {
  return (
    <div className="App">
    
      <Hero />
     
      <AddSection />
      <VirtualScroller />
      <Features />
     <ProductCarousel title={"navbar"} />
     <ProductCarousel title={"forms"} />
     <ProductCarousel title={"button"} />
     <ProductCarousel title={"animations"} />
     <ProductCarousel title={"loaders"} />
      <HomePage />

      <Teastimonals />
   <FoundersPage />
  
      <Footer />
    </div>
  );
}

export default App;
