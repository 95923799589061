import React from 'react';
import Header from './Header';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <Header />

      {/* Hero Section */}
      <section className="bg-gray-900 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">About Us</h1>
          <p className="text-xl">We are a team of passionate developers dedicated to creating high-quality code components.</p>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 bg-black">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 text-gradient-black-white">Meet Our Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            <div>
              <img src='https://cdn.britannica.com/47/188747-050-1D34E743/Bill-Gates-2011.jpg' alt="Bill Gates" className="rounded-full w-48 h-48 mx-auto mb-4 border-2 border-gray-600" />
              <h3 className="text-2xl font-semibold mb-2">Bill Gates</h3>
              <p className="text-lg">Full Stack Developer</p>
            </div>
            <div>
              <img src='https://th.bing.com/th/id/OIP.gkf3yxICbp-OJc1JfJcq6gAAAA?rs=1&pid=ImgDetMain' alt="Mark Zuckerberg" className="rounded-full w-48 h-48 mx-auto mb-4 border-2 border-gray-600" />
              <h3 className="text-2xl font-semibold mb-2">Mark Zuckerberg</h3>
              <p className="text-lg">Front-end Developer</p>
            </div>
            <div>
              <img src='https://th.bing.com/th/id/R.1d08d4e958019e1ef7764661b7af0dfb?rik=UR8uhJZC406LiA&riu=http%3a%2f%2fwww.thefamouspeople.com%2fprofiles%2fimages%2felon-musk-4.jpg&ehk=IXYblV7jhnH6xq3knIJLaQuYUpgBPcmPahLWRxsdfc4%3d&risl=&pid=ImgRaw&r=0' alt="Elon Musk" className="rounded-full w-48 h-48 mx-auto mb-4 border-2 border-gray-600" />
              <h3 className="text-2xl font-semibold mb-2">Elon Musk</h3>
              <p className="text-lg">Back-end Developer</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutUs;
