import React, { useState, useEffect, useRef } from 'react';
import { LiveProvider, LivePreview, LiveError } from 'react-live';
import axios from 'axios';
import * as ReactIcons from 'react-icons/fa'; // Import all icons from react-icons/fa
import html2canvas from 'html2canvas';
const CLOUD_URL = process.env.REACT_APP_IMAGE_CLOUD;
const PRESET = process.env.REACT_APP_IMAGE_PRESET;

const CodeUploadPage = () => {
  const [jsxCode, setJsxCode] = useState(`
    function Counter() {
      const [count, setCount] = React.useState(0);

      const increment = () => setCount(count + 1);
      const decrement = () => setCount(count - 1);

      return (
        <div className="counter">
          <h1>Counter: {count}</h1>
          <div>
            <button onClick={increment} className="increment-btn"><FaPlus /> Increment</button>
            <button onClick={decrement} className="decrement-btn"><FaMinus /> Decrement</button>
          </div>
        </div>
      );
    }
    render(<Counter />);
  `);
  const [cssCode, setCssCode] = useState(`
    .counter {
      text-align: center;
      color: #ffffff;
      background-color: #1e293b;
      padding: 20px;
      border-radius: 10px;
    }

    .counter h1 {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    .counter button {
      margin: 0 10px;
      padding: 10px 20px;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .increment-btn {
      background-color: #4caf50;
      color: #fff;
    }

    .decrement-btn {
      background-color: #f44336;
      color: #fff;
    }

    .increment-btn:hover {
      background-color: #45a049;
    }

    .decrement-btn:hover {
      background-color: #e53935;
    }
  `);
  const [codeType, setCodeType] = useState('button');
  const [isUploading, setIsUploading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success popup
  const previewRef = useRef(null);
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const styleTag = document.getElementById('dynamic-styles');
    if (styleTag) {
      styleTag.innerHTML = cssCode;
    } else {
      const newStyleTag = document.createElement('style');
      newStyleTag.id = 'dynamic-styles';
      newStyleTag.innerHTML = cssCode;
      document.head.appendChild(newStyleTag);
    }
  }, [cssCode]);

  const handleUpload = async () => {
    if (codeType === '') {
      alert('Please select a code type.');
      return;
    }

    setIsUploading(true);
    try {
      // Take a screenshot of the preview
      const canvas = await html2canvas(previewRef.current);
      const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));

      // Upload screenshot to Cloudinary
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('upload_preset', PRESET);

      const response = await axios.post(CLOUD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });


      const imageUrl = response.data.secure_url;

      // Upload the code along with the image URL to your backend
      const backendResponse = await axios.post(`${backendUrl}/code/addComponent`, {
        jsxCode,
        cssCode,
        type: codeType,
        imageUrl,
      }, {
        withCredentials: true
      });

      setShowSuccessPopup(true); // Show success popup on successful upload
    } catch (error) {
      console.error('Error uploading code or screenshot:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      {/* How to Use Button */}
      <button
        onClick={() => setShowPopup(true)}
        className='flex gap-2 py-4'
      >
        <span className='border-2 border-white rounded-full p-1'>  <ReactIcons.FaQuestion /></span> How to Use
      </button>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-1/3 max-w-md">
            <h3 className="text-xl font-bold mb-4">How to Use</h3>
            <p className="mb-4">
              To use this page, enter your JSX and CSS code into the respective text areas. You can select the type of code you are uploading using the dropdown menu. Don't add any imports for using useState or useEffect React hooks, use React.hookName e.g., React.useState(0). For React icons, use only react-icons/fa icons. You can use Tailwind CSS, but don't use export statements like in the code provided in the input.
            </p>
            <button
              onClick={() => setShowPopup(false)}
              className="bg-red-600 hover:bg-red-700 text-white font-bold rounded-md py-2 px-4"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-1/3 max-w-md">
            <h3 className="text-xl font-bold mb-4">Success!</h3>
            <p className="mb-4">Your code and screenshot have been successfully uploaded.</p>
            <button
              onClick={() => setShowSuccessPopup(false)}
              className="bg-green-600 hover:bg-green-700 text-white font-bold rounded-md py-2 px-4"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Code Type Selector */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
        <label className="block text-sm font-medium mb-2" htmlFor="codeType">
          Type of Code
        </label>
        <select
          id="codeType"
          value={codeType}
          onChange={(e) => setCodeType(e.target.value)}
          className="w-full p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="button">Button</option>
          <option value="navbar">Navbar</option>
          <option value="animations">Animations</option>
          <option value="forms">Forms</option>
          <option value="loaders">Loaders</option>
          <option value="other">Other</option>
        </select>
      </div>

      {/* Component Preview */}
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Component Preview</h2>
        <LiveProvider code={jsxCode} scope={{ React, ...ReactIcons }} noInline>
          <div ref={previewRef} className="w-full bg-gray-700 rounded-md p-4 mb-4">
            <LivePreview />
            <LiveError className="text-red-500 mt-4" />
          </div>
        </LiveProvider>
      </div>

      {/* Code Inputs */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* JSX Input */}
        <div className="bg-gray-800 rounded-lg shadow-lg p-6">
          <label className="block text-sm font-medium mb-2" htmlFor="jsxCode">
            JSX Code
          </label>
          <textarea
            id="jsxCode"
            value={jsxCode}
            onChange={(e) => setJsxCode(e.target.value)}
            className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 scrollbar-hidden"
            placeholder="Paste your JSX code here..."
          />
        </div>

        {/* CSS Input */}
        <div className="bg-gray-800 rounded-lg shadow-lg p-6">
          <label className="block text-sm font-medium mb-2" htmlFor="cssCode">
            CSS Code
          </label>
          <textarea
            id="cssCode"
            value={cssCode}
            onChange={(e) => setCssCode(e.target.value)}
            className="w-full h-60 p-4 bg-gray-700 text-white rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 scrollbar-hidden"
            placeholder="Paste your CSS code here..."
          />
        </div>
      </div>

      {/* Upload Button */}
      <button
        onClick={handleUpload}
        disabled={isUploading}
        className={`w-full py-4 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded-md ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isUploading ? 'Uploading...' : 'Upload Code and Screenshot'}
      </button>
    </div>
  );
};

export default CodeUploadPage;
