import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UpdateBidModal from './BidUpdatePage';

const AllBidsAdmin = () => {
  const [bids, setBids] = useState([]);
  const [selectedBid, setSelectedBid] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBids = async () => {
      try {
        const response = await axios.get(`${backendUrl}/bid/bids/all`, {
          withCredentials: true,
        });
        console.log(response.data.data);
        setBids(response.data.data);
      } catch (error) {
        console.error('Error fetching bids:', error);
      }
    };

    fetchBids();
  }, [backendUrl]);

  const handleView = (bidId) => {
    navigate(`/bids/${bidId}`);
  };

  const handleUpdate = (bid) => {
    setSelectedBid(bid);
    setShowUpdateModal(true);
  };

  const closeModal = () => {
    setShowUpdateModal(false);
    setSelectedBid(null);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 md:p-8">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8">Bids</h1>
      <div className="overflow-x-auto">
        <table className="w-full text-white bg-gray-800 rounded-lg shadow-lg">
          <thead>
            <tr className="border-b border-gray-700">
              <th className="text-left p-2 md:p-4">Username</th>
              <th className="text-left p-2 md:p-4">Budget</th>
              <th className="text-left p-2 md:p-4">Admin Status</th>
              <th className="text-left p-2 md:p-4">Completion Date</th>
              <th className="text-left p-2 md:p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {bids.map((bid) => (
              <tr key={bid._id} className="border-b border-gray-700">
                <td className="p-2 md:p-4">{bid.username}</td>
                <td className="p-2 md:p-4">${bid.budget}</td>
                <td className="p-2 md:p-4">{bid.AdminStatus}</td>
                <td className="p-2 md:p-4">{new Date(bid.completionDate).toLocaleDateString()}</td>
                <td className="p-2 md:p-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                  <button
                    onClick={() => handleView(bid._id)}
                    className="bg-blue-500 hover:bg-blue-600 px-3 py-1 md:px-4 md:py-2 rounded-lg text-sm md:text-base"
                  >
                    View
                  </button>
                  <button
                    onClick={() => handleUpdate(bid)}
                    className="bg-yellow-500 hover:bg-yellow-600 px-3 py-1 md:px-4 md:py-2 rounded-lg text-sm md:text-base"
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showUpdateModal && selectedBid && (
        <UpdateBidModal
          bid={selectedBid}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default AllBidsAdmin;
