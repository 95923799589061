import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';  // Import useNavigate
import Modal from "../Animations/Congragulation";

const PaymentPage = () => {
    const [loading, setLoading] = useState(false);
    const [refral, setRefral] = useState('');
    const [rStatus, setrStatus] = useState("0");
    const [price, setPrice] = useState();
    const id = useParams().id;
    const uri = process.env.REACT_APP_API_URL;
    const Key = process.env.REACT_APP_PAYMENT_KEY;
    const [product, setProduct] = useState();
    const [userData, setUserData] = useState('');
    const [showModal, setShowModal] = useState(false); 
    const navigate = useNavigate();  // Create navigate object
  
    const fetchProductById = async () => {
        try {
            const response = await axios.get(`${uri}/product/productById/${id}`, { withCredentials: true });
            setProduct(response.data);
            setPrice(response.data.price);
        } catch (error) {
            console.error(error);
        }
    };

    const handleApplyReferral = async () => {
        try {
            const code = await axios.post(`${uri}/auth/findRefral`, { refralCode: refral }, { withCredentials: true });
            if (code.status === 200) {
                setrStatus("1");
                setPrice(product.price - (product.price * 20) / 100);
            } else {
                setrStatus('2');
                setPrice(product.price);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${uri}/auth/findUser`, { withCredentials: true });
            setUserData(response.data.foundUser);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const paymentSuccessHandle = async (response) => {
        try {
            await axios.post(`${uri}/transactions/paymentSuccess`, { response, id, product, price, refral, category: 'project' }, { withCredentials: true });
            setShowModal(true);
            // Redirect after payment success
            setTimeout(() => {
                navigate('/user/myPurchases');  // Change '/thank-you' to your preferred route
            }, 3000);  // Optional delay before redirection
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchProductById();
        fetchUserData();
    }, []);

    const handlePayment = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${uri}/transactions/initate`, { amount: price,data:"sahil" }, { withCredentials: true });
            const { orderId } = response.data;
            const options = {
                key: Key,
                amount: price * 100, 
                currency: 'INR',
                name: 'COOOUU',
                description: 'Product Purchase',
                order_id: orderId,
                handler: function (response) {
                    axios.post(`${uri}/transactions/sendRecepit`, {}, { withCredentials: true });
                    alert('Payment Successful!');
                    paymentSuccessHandle(response);
                    setLoading(false);
                },
                prefill: {
                    name: userData.username,
                    email: userData.email,
                    contact: ''
                },
                theme: {
                    color: '#F37254'
                }
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error processing payment:', error);
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-8">
            {product && rStatus === "1" ? (
                <h1 className="text-2xl mb-4">Total Amount: ₹{product.price - (product.price * 20) / 100}</h1>
            ) : product ? (
                <h1 className="text-2xl mb-4">Total Amount: ₹{product.price}</h1>
            ) : null}

            <div className="mb-4">
                <label htmlFor="referral" className="block text-sm font-medium text-gray-700">Have A Referral Code</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input 
                        type="text" 
                        id="referral" 
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" 
                        value={refral} 
                        onChange={(e) => setRefral(e.target.value)} 
                    />
                    <button 
                        className="ml-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                        onClick={handleApplyReferral}
                    >
                        Apply
                    </button>
                </div>
                {rStatus === "1" ? (
                    <p className="text-green-600 mt-2">Code Applied Successfully!</p>
                ) : rStatus === '2' ? (
                    <p className="text-red-600 mt-2">Invalid Referral Code</p>
                ) : null}
            </div>

            <button 
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                onClick={handlePayment} 
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Pay Now'}
            </button>

            {loading && (
                <div className="mt-4 flex justify-center">
                    <div className="loader"></div>
                </div>
            )}

            {showModal && <Modal className="top-0 relative" onClose={() => setShowModal(false)} />}  
        </div>
    );
};

export default PaymentPage;
