import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProductList = ({content,route,route2}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const type = localStorage.getItem('type');
  const uri = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${uri}/${route}/${route2}/${content}`, {
        withCredentials: true
      });
      if (response) {
        setProducts(response.data);
        setLoading(false);
       
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleUpdate = (id) => {
    navigate(`/BuyProduct/${id}`);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="container mx-auto mt-10 p-4 md:p-8 bg-gray-800 rounded-lg shadow-md">
        <h2 className="text-3xl md:text-4xl font-bold text-gradient-black-white mb-6 md:mb-8 text-center">${content} Projects</h2>
        <div className="flex flex-col gap-4">
          {products && products.map((product, index) => (
            <div 
              key={index} 
              className="flex flex-col  md:flex-row items-center border rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out transform hover:scale-105 bg-gray-700"
            >
              <div className="w-full md:w-1/3">
                <img 
                  src={product.image[0]} 
                  className="w-full h-40 object-cover" 
                  alt='Product' 
                />
              </div>
              <div className="w-full md:w-2/3 p-4">
                <h5 className="text-lg md:text-xl font-semibold mb-2 text-gray-100">{product.title}</h5>
                <p className="text-gray-300 text-sm md:text-lg mb-1">Price: <span className="text-gray-100 font-semibold">₹{product.price}</span></p>
                <p className="text-gray-300 text-sm md:text-lg mb-2">Seller: <span className="text-gray-100 font-semibold">{product.seller}</span></p>
                <a href={product.deployedLink} className="text-gray-300 text-sm md:text-lg mb-2 hover:text-blue-400">Deployed Link <span className="text-blue-400 font-semibold">Click here</span></a>

                <div className="flex items-center justify-center md:justify-end">
                  {type === "admin" || content == 'user'? (
                    <button 
                      className="bg-gray-600 text-white px-4 py-1 rounded hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                      onClick={() => navigate(`/updateProduct/${product.productId}`)}
                    >
                      Update
                    </button>
                  ) : (
                    <button 
                      className="bg-gray-600 text-white px-4 py-1 rounded hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                      onClick={() => handleUpdate(product.productId)}
                    >
                      View Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
