import React from 'react';
import { FaLaptopCode, FaRocket, FaShieldAlt } from 'react-icons/fa';
import image1 from "../../assests/Design4.png";
import image2 from "../../assests/Design3.png";
import image3 from "../../assests/secure.jpeg";

const features = [
  {
    title: "High-Quality Components",
    description: "Get access to high-quality code components crafted by experts.",
    icon: <FaLaptopCode />,
    bgImage: image1,
  },
  {
    title: "Fast and Efficient",
    description: "Download and integrate components quickly to boost your development.",
    icon: <FaRocket />,
    bgImage: image2,
  },
  {
    title: "Secure Transactions",
    description: "Secure payment gateways to ensure safe and hassle-free transactions.",
    icon: <FaShieldAlt />,
    bgImage: image3,
  },
];

const Features = () => {
  return (
    <section className="bg-gray-800 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl text-gradient-black-white bg-gradient-to-r from-black to-transparent font-bold mb-12">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="relative group h-80 rounded-xl shadow-lg overflow-hidden transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl"
              style={{ 
                backgroundImage: `url(${feature.bgImage})`,
                backgroundColor:"black",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out group-hover:bg-opacity-70"></div>
              <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
                <div className="text-6xl mb-4 text-white transition-transform duration-300 ease-in-out group-hover:translate-y-0 group-hover:scale-110 transform translate-y-4 scale-90">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold text-white mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                  {feature.title}
                </h3>
                <p className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
