import React from 'react';
import { FaCamera, FaCode } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center">
      <div className="text-center mb-12">
        <h1 className="text-5xl font-bold mb-4">
          Welcome to <span className="text-gray-400">Image to Code Converter</span>
        </h1>
        <p className="text-lg text-gray-300">
          Transform your images into clean, usable code effortlessly.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl">
          <FaCamera className="text-6xl mb-4 text-gray-200" />
          <h2 className="text-xl font-semibold mb-2">Upload Your Image</h2>
          <p className="text-gray-400 text-center">
            Capture or upload an image that you want to convert. Drag and drop or use the upload button.
          </p>
        </div>
        <div className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl">
          <FaCode className="text-6xl mb-4 text-gray-200" />
          <h2 className="text-xl font-semibold mb-2">Get Your Code</h2>
          <p className="text-gray-400 text-center">
            Receive the converted code instantly, tailored to your requirements. Easy copy and paste.
          </p>
        </div>
      </div>
      <div className="mt-12">
        <button
          onClick={()=>navigate("/component/getCode")}
          className="inline-flex items-center px-6 py-3 bg-gray-700 text-white rounded-full shadow-lg hover:bg-gray-600"
        >
          Learn More
          <IoIosArrowForward className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default HomePage;
