import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AllProjects = () => {
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState('all');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${backendUrl}/product/allProducts/all`, {
          withCredentials: true,
        });
        setProjects(response.data);
        setFilteredProjects(response.data); // Initialize filtered projects with all projects
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [backendUrl]);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(
        projects.filter((project) => project.adminStatus === filter)
      );
    }
  }, [filter, projects]);

  const handleStatusUpdate = async (projectId, newStatus) => {
    try {
      await axios.put(`${backendUrl}/product/updateProduct/${projectId}`, { adminStatus: newStatus }, { withCredentials: true });
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === projectId ? { ...project, adminStatus: newStatus } : project
        )
      );
      console.log('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleView = (projectId) => {
    navigate(`/BuyProduct/${projectId}`);
  };

  const handleUpdate = (projectId) => {
    navigate(`/updateProduct/${projectId}`);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 sm:p-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8">Projects</h1>

      {/* Filter section */}
      <div className="mb-4 flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0">
        <label htmlFor="filter" className="mr-4">Filter by Status:</label>
        <select
          id="filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="bg-gray-700 text-white px-4 py-2 rounded-lg w-full sm:w-auto"
        >
          <option value="all">All</option>
          <option value="accepted">Approved</option>
          <option value="pending">Pending</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-white bg-gray-800 rounded-lg shadow-lg min-w-max">
          <thead>
            <tr>
              <th className="text-left p-2 sm:p-4">Title</th>
              <th className="text-left p-2 sm:p-4">Seller</th>
              <th className="text-left p-2 sm:p-4">Price</th>
              <th className="text-left p-2 sm:p-4">Admin Status</th>
              <th className="text-left p-2 sm:p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProjects.map((project) => (
              <tr key={project._id} className="border-b border-gray-700">
                <td className="p-2 sm:p-4">{project.title}</td>
                <td className="p-2 sm:p-4">{project.seller}</td>
                <td className="p-2 sm:p-4">${project.price}</td>
                <td className="p-2 sm:p-4">
                  <select
                    value={project.adminStatus}
                    onChange={(e) => handleStatusUpdate(project.productId, e.target.value)}
                    className="bg-gray-700 text-white px-2 py-1 sm:px-4 sm:py-2 rounded-lg"
                  >
                    <option value="accepted">Approved</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </td>
                <td className="p-2 sm:p-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                  <button
                    onClick={() => handleUpdate(project.productId)}
                    className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded-lg w-full sm:w-auto"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleView(project.productId)}
                    className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg w-full sm:w-auto"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllProjects;
